import Ekow from "./images/tom.png"
import Joel from "./images/Image-1.png"
import Nat from "./images/Image-2.png"
import Jerry from "./images/jerry.png"


const people = [
    {
        id: 1,
        pic: Ekow,
        name: 'Ekow Thompson',
        testimony: 'Dosh insurance company prioritizes customer satisfaction and is committed to providing exceptional service to our clients.'
    },
    {
        id: 2,
        pic: Joel,
        name: 'Frank Kay',
        testimony: 'Dosh insurance company prioritizes customer satisfaction and is committed to providing exceptional service to our clients.'

    },
    {
        id: 3,
        pic: Nat,
        name: 'Joel Nettey',
        testimony: `DOSH allows me to pay friends and family without any fees. It's a hassle-free way to transfer money while saving on charges. Thanks to DOSH, I can easily reimburse and support loved ones without worrying about additional costs.`
    },
    {
        id: 4,
        pic: Jerry,
        name: 'Jerry Sam',
        testimony: `DOSH insurance has been a game-changer for me. Their seamless claims process and comprehensive coverage gave me peace of mind. I've saved significantly on my premiums without compromising on quality. The customer service is top-notch, always ready to assist promptly. Choosing DOSH was one of the best decisions I made for my insurance needs.`
    },
]
export default people;

// data.js
export const hospitalData = {
    "Greater Accra": [
        { name: "Trust Hospital", region: "Greater Accra", district: "Korle Klottey Municipal", latitude: "5°33'45.1\"N", longitude: "0°10'55.6\"W", contact: "0302761974/0302761975", email: "info@thetrusthospital.com" },
        { name: "International Maritime Hospital", region: "Greater Accra", district: "Tema Municipal", latitude: "5°40'27.2\"N", longitude: "0°01'30.6\"W", contact: "0303220030", email: "info@imah.com.gh" },
        { name: "Esidem Hospital", region: "Greater Accra", district: "La Nkwantanang-Madina", latitude: "5°39'36.2\"N", longitude: "0°09'18.7\"W", contact: "0302520345", email: "esidemhospital.org@gmail.com" },
        { name: "Aneeja Surgical & Medical Hospital", region: "Greater Accra", district: "Ga West", latitude: "5.640730", longitude: "-0.263060", contact: "0302411491", email: "administration@aneeja-hospital.com" },
        { name: "Midway Hospital", region: "Greater Accra", district: "La Dade Kotopon Municipal District", latitude: "5°37'02.3\"N", longitude: "0°13'35.3\"W", contact: "0548348900/0302950173", email: "info@midwayclinicgh.com" },
        { name: "Afrah Int Hospital", region: "Greater Accra", district: "Ayawaso West Municipal District", latitude: "5°34'11.8\"N", longitude: "0°12'21.8\"W", contact: "0302267151/0557518126", email: "afrahinternationalhospital@gmail.com" },
        { name: "Mother Love Hospital", region: "Greater Accra", district: "Adentan Municipality", latitude: "5°42'59.6\"N", longitude: "0°09'58.9\"W", contact: "0201424011", email: "motherlovehospital@gmail.com" },
        { name: "Bengali Hospital", region: "Greater Accra", district: "Tema Municipal", latitude: "5°40'01.4\"N", longitude: "0°01'10.4\"W", contact: "0206301354", email: "" },
        { name: "Sakumono Community Hospital", region: "Greater Accra", district: "Tema Metropolitan District", latitude: "5°37'21\"N", longitude: "0°03'46\"W", contact: "0303403515", email: "sakumonocommunityhospital.org" },
        { name: "Jubail Hospital", region: "Greater Accra", district: "Tema Metropolitan District", latitude: "5.6257° N", longitude: "0.0519° W", contact: "0303401167/030401403/0508743886", email: "info@jubailhospital.com" },
        { name: "Accra Ridge Hospital", region: "Greater Accra", district: "Osu Klottey Sub-Metro", latitude: "5.5614° N", longitude: "0.1987° W", contact: "0247814770/0302428460", email: "info@garh.gov.gh" },
        { name: "Tema General Hospital", region: "Greater Accra", district: "Tema Municipal", latitude: "5°40'27.2\"N", longitude: "0°01'30.6\"W", contact: "0303302695", email: "temageneralhospital2017@gmail.com" },
        { name: "Airport Women Hospital", region: "Greater Accra", district: "Accra Metropolitan District", latitude: "5°37'04\"N", longitude: "0°10'44\"W", contact: "0302736107", email: "info@airportwomenshospital.com" },
        { name: "Korle Bu Teaching Hospital", region: "Greater Accra", district: "Ablekuma South District", latitude: "5.5366° N", longitude: "0.2264° W", contact: "0302739510/0243407809", email: "info@kbth.gov.gh" },
        { name: "Lekma Hospital", region: "Greater Accra", district: "Ledzokuku Municipal District", latitude: "5.6110° N", longitude: "0.0860° W", contact: "0207826608/0302717945", email: "lekmahospital12@gmail.com" },
        { name: "Claron Health International", region: "Greater Accra", district: "Accra Metropolitan District", latitude: "5°36'44\"N", longitude: "0°10'56\"W", contact: "0302771017", email: "wellness@claronhealth.com" },
        { name: "Lapaz Community Hospital", region: "Greater Accra", district: "Accra Metropolitan District", latitude: "5.6086° N", longitude: "0.2536° W", contact: "0302688181/030268817", email: "info@mogcsp.gov.gh" },
        { name: "Grace Anchor Hospital", region: "Greater Accra", district: "Nsawam-Adoagyire Municipal District", latitude: "5°44'06.2\"N", longitude: "0°21'38.5\"W", contact: "0598055595", email: "graceanchormedicalcentre.com" },
        { name: "Bemuah Royal Hospital", region: "Greater Accra", district: "Ayawaso West Municipal District", latitude: "5°38'45\"N", longitude: "0°09'25\"W", contact: "0302543652", email: "info@bemuahhospital.com" },
        { name: "Mab International Hospital", region: "Greater Accra", district: "Accra Metropolitan District", latitude: "5°36'04.7\"N", longitude: "0°15'24.5\"W", contact: "0209494300/054998242", email: "info@mabhospitals.org" },
        { name: "Tantra Community Hospital", region: "Greater Accra", district: "Ga West", latitude: "5.6435° N", longitude: "0.2624° W", contact: "0501395077", email: "tantracommunityclinic@yahoo.com" },
        { name: "Bethel Sp. Hospital", region: "Greater Accra", district: "Tema Municipal", latitude: "5°40'19.2\"N", longitude: "0°01'11.0\"W", contact: "0302953091", email: "info@bethelspecialisthospital.com" },
        { name: "Ada East District Hospital", region: "Greater Accra", district: "Ada East District", latitude: "5°53'21.8\"N", longitude: "0°34'11.0\"E", contact: "0244860258", email: "rukymike18@yahoo.com" },
        { name: "Achimota Hospital", region: "Greater Accra", district: "Accra Metropolitan District", latitude: "5°37'46.2\"N", longitude: "0°13'01.3\"W", contact: "0302400212/0302400212", email: "info@achimotahospital.com" },
        { name: "New Crystal Hospital", region: "Greater Accra", district: "Ashaiman Municipal District", latitude: "5°41'25\"N", longitude: "0°02'4\"W", contact: "0244278434/0303307974", email: "info@newcrystalhealth.org" },
        { name: "New Crystal Hospital", region: "Greater Accra", district: "Tema Municipal", latitude: "5°44'12\" N", longitude: "0°00'52\"W", contact: "0302977872/0544347239", email: "info@newcrystalhealth.org" },
        { name: "New Crystal Hospital", region: "Greater Accra", district: "Tema West District", latitude: "5°40'14.8\"N", longitude: "0°00'46.2\"W", contact: "0544314384", email: "info@newcrystalhealth.org" },
        { name: "Hag Hospital", region: "Greater Accra", district: "Ayawaso West Municipal District", latitude: "5.6674° N", longitude: "0.1166° W", contact: "0552617851", email: "care@haghospital.com" },
        { name: "Healthnet", region: "Greater Accra", district: "Accra Metropolitan District", latitude: "5°33'15\"N", longitude: "0°10'19\"W", contact: "0551420412/0508673058", email: "info@healthnetgh.com" },
        { name: "Ideal Clinic", region: "Greater Accra", district: "Accra Metropolitan District", latitude: "5.6001° N", longitude: "0.2066° W", contact: "0302700085", email: "info@idealclinic.com" },
        { name: "Accra Medical Centre", region: "Greater Accra", district: "Osu Klottey Sub-Metro", latitude: "5.5687° N", longitude: "0.1860° W", contact: "0204096099", email: "info@accramed.com" },
        { name: "University of Ghana Medical Center", region: "Greater Accra", district: "Ayawaso West Municipal District", latitude: "5° 37' 55\"N", longitude: "0° 11' 9\"W", contact: "0302550843", email: "info-msrc@ugmc.ug.edu" },
        { name: "Nyaho Medical Centre", region: "Greater Accra", district: "Accra Metropolitan District", latitude: "5.6145° N", longitude: "0.1851° W", contact: "0501436662", email: "info.ac@yahomedical.com" },
        { name: "Unimed Hospital Limited", region: "Greater Accra", district: "Accra Metropolitan District", latitude: "5.6545° N", longitude: "0.2712° W", contact: "0303976687", email: "unimedhealth@yahoo.com" },
        { name: "Lena Health Service Limited", region: "Greater Accra", district: "Tema Metropolitan District", latitude: "5°40'22.6\"N", longitude: "0°01'12.2\"W", contact: "0501583362", email: "info@lenahouseclinic.com" },
        { name: "Synlab", region: "Greater Accra", district: "Accra Metropolitan District", latitude: "5°37'28.9\"N", longitude: "0°11'01.7\"W", contact: "0593941609/0501232694", email: "baidengharty@gmail.com" },
        { name: "Quantum Total Wellness Centre", region: "Greater Accra", district: "Ablekuma West Municipal", latitude: "5°34'02.3\"N", longitude: "0°15'26.3\"W", contact: "0558156422/0540620581", email: "eboadu@quantumtotalwellness.com" },
        { name: "Sunshine Diagnostic center", region: "Greater Accra", district: "Accra Metropolitan District", latitude: "5°36'39.8\"N", longitude: "0°10'38.3\"W", contact: "0263006653", email: "info@sunshinehealthcareltd@gmail.com" },
        { name: "Halleman Medical Services", region: "Greater Accra", district: "Ga East Municipal District", latitude: "5°40'05.0\"N", longitude: "0°14'21.5\"W", contact: "0556780542/0244733076", email: "" },
        { name: "Euracare", region: "Greater Accra", district: "Accra Metropolitan District", latitude: "5.5705° N", longitude: "0.1765° W", contact: "0302739390", email: "info@euracare.com.gh" },
        { name: "Nova Wellness Center", region: "Greater Accra", district: "Ayawaso West Municipal District", latitude: "5°36'46.1\"N", longitude: "0°11'53.0\"W", contact: "0269952077", email: "info@novawellnessgh.com" },
        { name: "Global Med Rehab", region: "Greater Accra", district: "Ga East Municipal District", latitude: "5°41'19.7\"N", longitude: "0°16'05.9\"W", contact: "0548438137", email: "globalmedrehab@gmail.com" },
        { name: "Cassi Medical & Diagnostics Centre", region: "Greater Accra", district: "Accra Metropolitan District", latitude: "5°36'13.7\"N", longitude: "0°10'39.2\"W", contact: "0308231076", email: "info.cassimedicalanddiagnostics@gmail.com" },
        { name: "Mab Medical Center", region: "Greater Accra", district: "Accra Metropolitan District", latitude: "5°36'03.8\"N", longitude: "0°15'24.4\"W", contact: "0302959539", email: "info@mabhospital.org" },
        { name: "Elite Care Medical Centre", region: "Greater Accra", district: "Ayawaso West Municipal District", latitude: "5°39'47.0\"N", longitude: "0°07'51.8\"W", contact: "0537912291", email: "admin@elitecarecentre.com" },
        { name: "The Diagnostic Centre", region: "Greater Accra", district: "Ayawaso West Municipal District", latitude: "5°6317' N", longitude: "0°1742' W", contact: "0509457991", email: "info@dclgh.com" },
        { name: "Three Mc Ghana Limited", region: "Greater Accra", district: "Tema Metropolitan District", latitude: "5°38'36.8\"N", longitude: "0°09'10.1\"W", contact: "0302987133", email: "info@3mcsystems.com" },
        { name: "Best Heart Medical Centre Limited", region: "Greater Accra", district: "Ga West", latitude: "5°6748' N", longitude: "0°3310' W", contact: "0549368489", email: "halidusalam1@gmail.com" },
        { name: "Sherperdcare Medical Centre", region: "Greater Accra", district: "Tema Metropolitan District", latitude: "5°39'25.0\"N", longitude: "0°02'54.8\"W", contact: "0307030225", email: "shepherdscaremc@gmail.com" },
        { name: "Ark Medical Centre", region: "Greater Accra", district: "Accra Metropolitan District", latitude: "5°6067' N", longitude: "0°2521' W", contact: "0543236133", email: "arkmedicalgh@gmail.com" },
        { name: "Blue Valley Specialist", region: "Greater Accra", district: "Weija Gbawe Municipal District", latitude: "5°33'12.4\"N", longitude: "0°19'26.2\"W", contact: "0509461750", email: "bvalleymedical@gmail.com" },
        { name: "Natural Way Hebal Clinic", region: "Greater Accra", district: "Accra Metropolitan District", latitude: "5°35'44.5\"N", longitude: "0°16'29.4\"W", contact: "0200792466", email: "naturalwayherbalclinic19@gmail.com" },
        { name: "Rinat Clinic", region: "Greater Accra", district: "Ayawaso West Municipal District", latitude: "5°39'07.0\"N", longitude: "0°07'52.6\"W", contact: "0302515901", email: "iagbesse@rabitoclinic.com" },
        { name: "Lakeside Clinic", region: "Greater Accra", district: "Adentan Municipality", latitude: "5°5923' N", longitude: "0°2310' W", contact: "0302230496", email: "" },
        { name: "Blessings Clinic Ltd", region: "Greater Accra", district: "Accra Metropolitan District", latitude: "5°5991' N", longitude: "0°2323' W", contact: "0302235696", email: "" },
        { name: "Sandford World Clinic", region: "Greater Accra", district: "Adentan Municipality", latitude: "5°41'14.3\"N", longitude: "0°10'34.7\"W", contact: "0302974161", email: "swc@sandfordhealth.org" },
        { name: "Theresa Clinic", region: "Greater Accra", district: "Okaikoi", latitude: "5°5785' N", longitude: "0°2347' W", contact: "0244411394", email: "theresasclinic@gmail.com" },
        { name: "Efan Victory Clinic", region: "Greater Accra", district: "Krowor Muinicipal", latitude: "5° 35' 55\"N", longitude: "0° 11' 56\"W", contact: "0243229070", email: "efanvictoryclinic2@gmail.com" },
        { name: "Ashaiman Polyclinic", region: "Greater Accra", district: "Ashaiman Municipal District", latitude: "5° 41' 11\"N", longitude: "0° 2' 23\"W", contact: "0265898684", email: "ashiamanpolyclinic.iv@gmail.com" },
        { name: "Medcare plus clinic", region: "Greater Accra", district: "Accra Metropolitan District", latitude: "5°36'24.5\"N", longitude: "0°11'52.4\"W", contact: "0302540132", email: "medcareplusclinic@gmail.com" },
        { name: "Dansoman Polyclinic", region: "Greater Accra", district: "Ablekuma West Municipal", latitude: "5° 33' 40\" N", longitude: "0° 15' 36\" W", contact: "0205369054/0302304592", email: "dansomanpolyclinic@gmail.com" },
        { name: "Ogbojo Polyclinic", region: "Greater Accra", district: "Adentan Municipality", latitude: "5°39'42.6\" N", longitude: "0°08'40.3\" W", contact: "0555368374", email: "diana.donkor@ghs.gov.gh" },
        { name: "Anyaa Polyclinic", region: "Greater Accra", district: "Ga Central Municipal", latitude: "5°35'51.3\" N", longitude: "0°17'19.9\" W", contact: "0555258123", email: "" },
        { name: "Tema Polyclinic", region: "Greater Accra", district: "Tema Municipal", latitude: "5.6359° N", longitude: "0.0068° W", contact: "0302944398", email: "" },
        { name: "St. Lukes Clinic", region: "Greater Accra", district: "Accra Metropolitan District", latitude: "5.6143° N", longitude: "0.1779° W", contact: "0302775405", email: "info@stlukesclinic.com.gh" },
        { name: "Prampram Polyclinic", region: "Greater Accra", district: "Ningo Prampram District", latitude: "5.7117° N", longitude: "0.1095° E", contact: "0303910075/024358462", email: "alexander.balinia-adda@ghs.gov.gh" },
        { name: "Kaneshie Polyclinic", region: "Greater Accra", district: "Accra Metropolitan District", latitude: "5° 34' 28\" N", longitude: "0° 13' 51\" W", contact: "0302228288", email: "" },
        { name: "Rabito Clinic", region: "Greater Accra", district: "Tema Municipal", latitude: "5°43'39.7\" N", longitude: "0°01'16.1\" E", contact: "0596914333", email: "info@rabitoclinic.com" },
        { name: "Rabito Clinic", region: "Greater Accra", district: "Ablekuma West Municipal", latitude: "5°32'41.9\" N", longitude: "0°15'47.0\" W", contact: "0596913671", email: "info@rabitoclinic.com" },
        { name: "Rabito Clinic", region: "Greater Accra", district: "Ayawaso West Municipal District", latitude: "5°38'41.3\" N", longitude: "0°09'21.8\" W", contact: "0592420596", email: "info@rabitoclinic.com" },
        { name: "Rabito Clinic", region: "Greater Accra", district: "Tema Municipal", latitude: "5°39'57.0\" N", longitude: "0°01'06.7\" W", contact: "0596913672", email: "info@rabitoclinic.com" },
        { name: "Rabito Clinic", region: "Greater Accra", district: "Osu Klottey Sub-Metro", latitude: "5°33'53.7\" N", longitude: "0°10'45.4\" W", contact: "0302774526", email: "info@rabitoclinic.com" },
        { name: "Rabito Clinic", region: "Greater Accra", district: "Accra Metropolitan District", latitude: "5°36'00.8\" N", longitude: "0°14'17.5\" W", contact: "0596913669", email: "info@rabitoclinic.com" },
        { name: "Rabito Clinic", region: "Greater Accra", district: "Ayawaso West Municipal District", latitude: "5°38'32.5\" N", longitude: "0°09'37.6\" W", contact: "0594974375", email: "info@rabitoclinic.com" },
        { name: "Rabito Clinic", region: "Greater Accra", district: "Ga East Municipal District", latitude: "5°38'34.5\" N", longitude: "0°14'18.1\" W", contact: "0593854672", email: "info@rabitoclinic.com" },
        { name: "Rabito Clinic", region: "Greater Accra", district: "Accra Metropolitan District", latitude: "5°38'41.3\" N", longitude: "0°09'21.8\" W", contact: "0596914321", email: "info@rabitoclinic.com" },
        { name: "Oracare Dental Clinic", region: "Greater Accra", district: "Adentan Municipality", latitude: "5°42'20.0\" N", longitude: "0°08'53.9\" W", contact: "0302972742", email: "oracaredentalgh@gmail.com" },
        { name: "Jacob Dental Clinic", region: "Greater Accra", district: "Adentan Municipality", latitude: "5°42'07.5\" N", longitude: "0°07'21.8\" W", contact: "0542999699", email: "jacobdental@hotmail.com" },
        { name: "Marks Dental Clinic", region: "Greater Accra", district: "Osu Klottey Sub-Metro", latitude: "5°33'36.7\" N", longitude: "0°11'55.3\" W", contact: "0542999699", email: "info@marksdental-clinic.com" },
        { name: "Gilead Medical & Dental Centre", region: "Greater Accra", district: "Asylum Down District", latitude: "5°34'18.1\" N", longitude: "0°11'58.9\" W", contact: "0247595950", email: "info@gileadmedgh.com" },
        { name: "Save The Nations Sight Eye Clinic", region: "Greater Accra", district: "Ayawaso West Municipal District", latitude: "5°39'30.8\" N", longitude: "0°09'20.5\" W", contact: "0207574425", email: "jcibaah@yahoo.com" },
        { name: "Delcielo Optical Services", region: "Greater Accra", district: "Asylum Down District", latitude: "5°34'20.6\" N", longitude: "0°12'10.5\" W", contact: "0244702542", email: "info@delcielooptical.com" },
        { name: "Innus Eye & Vision Care Centre", region: "Greater Accra", district: "Ga East Municipal District", latitude: "5°38'30.3\" N", longitude: "0°09'06.1\" W", contact: "0302983354/0243690497", email: "inneseyecare@gmail.com" },
        { name: "My Eyes Center", region: "Greater Accra", district: "Ayawaso West Municipal District", latitude: "5°38'38.6\" N", longitude: "0°09'23.6\" W", contact: "0262651323", email: "myeyescentre@gmail.com" },
        { name: "Eye Express Limited", region: "Greater Accra", district: "Osu Klottey Sub-Metro", latitude: "5°33'32.0\" N", longitude: "0°12'03.5\" W", contact: "0277429279", email: "eye.express@yahoo.com" },
        { name: "Permeff Eye Care", region: "Greater Accra", district: "Ayawaso West Municipal District", latitude: "5°39'34.9\" N", longitude: "0°10'00.5\" W", contact: "0503261573", email: "permeffeyecare@gmail.com" },
        { name: "Amasha Partners Optical", region: "Greater Accra", district: "Tema Metropolitan District", latitude: "5°37'11.3\" N", longitude: "0°13'50.0\" W", contact: "0244331662", email: "ama.hotor@yahoo.com" },
        { name: "Iris Optometric", region: "Greater Accra", district: "Ablekuma South District", latitude: "5°32'06.7\" N", longitude: "0°13'53.6\" W", contact: "0277671221", email: "hello@iriseyecentre.com" },
        { name: "Third Eye And Vision Centre Limited", region: "Greater Accra", district: "Osu Klottey Sub-Metro", latitude: "5°34'14.0\" N", longitude: "0°12'00.7\" W", contact: "0245938389", email: "info@thirdeyecare.com" },
        { name: "Emmanuel Eye Centre", region: "Greater Accra", district: "Ayawaso West Municipal District", latitude: "5°37'49.6\" N", longitude: "0°10'28.0\" W", contact: "0302500578", email: "eemc@lukemissions.org" },
        { name: "Godly Favoured Eye Care Centre", region: "Greater Accra", district: "Accra Metropolitan District", latitude: "5°36'21.8\" N", longitude: "0°14'56.6\" W", contact: "0535196655", email: "godlyfavored555@gmail.com" },
        { name: "Top Up Pharmacy Limited (Adabraka)", region: "Greater Accra", district: "Korley Klottey Municipal", latitude: "", longitude: "", contact: "0242226555", email: "ella10030@gmail.com" },
        { name: "Top Up Pharmacy Limited (Osu)", region: "Greater Accra", district: "Osu Klottey Sub-Metro", latitude: "5°33'52.4\" N", longitude: "0°10'52.3\" W", contact: "0242226555", email: "ella10030@gmail.com" },
        { name: "Top Up Pharmacy Limited (Airport Resid.)", region: "Greater Accra", district: "Accra Metropolitan District", latitude: "5°36'36.7\" N", longitude: "0°10'58.6\" W", contact: "0508288478", email: "ella10030@gmail.com" },
        { name: "Top Up Pharmacy Limited (Airport Hills)", region: "Greater Accra", district: "Accra Metropolitan District", latitude: "5°36'23.6\" N", longitude: "0°08'23.8\" W", contact: "0506475825", email: "ella10030@gmail.com" },
        { name: "Top Up Pharmacy Limited (East Legon)", region: "Greater Accra", district: "Ayawaso West Municipal District", latitude: "5°38'34.6\" N", longitude: "0°08'51.2\" W", contact: "0501447384", email: "ella10030@gmail.com" },
        { name: "Add Pharmacy Gh Ltd (Bukum Branch)", region: "Greater Accra", district: "Accra Metropolitan District", latitude: "5°32'11.7\" N", longitude: "0°13'17.0\" W", contact: "050157460", email: "info@addpharma4u.com" },
        { name: "Add Pharmacy Gh Ltd (Darkuman Total Branch)", region: "Greater Accra", district: "Accra Metropolitan District", latitude: "5°34'37.1\" N", longitude: "0°15'16.6\" W", contact: "0501602138", email: "info@addpharma4u.com" },
        { name: "Add Pharma (Industrial Area)", region: "Greater Accra", district: "Accra Metropolitan District", latitude: "5°35'25.1\" N", longitude: "0°13'49.1\" W", contact: "0501257178", email: "info@addpharma4u.com" },
        { name: "Add Pharma (Asylum Down)", region: "Greater Accra", district: "Asylum Down District", latitude: "", longitude: "", contact: "024717667", email: "info@addpharma4u.com" },
        { name: "Add Pharmacy Gh Ltd (Tseddo Branch)", region: "Greater Accra", district: "La Dade Kotopon Municipal District", latitude: "5°34'56.6\" N", longitude: "0°08'32.2\" W", contact: "0501601120", email: "info@addpharma4u.com" },
        { name: "Add Pharmacy Gh Ltd (North Ridge Area)", region: "Greater Accra", district: "Osu Klottey Sub-Metro", latitude: "5°34'23.9\" N", longitude: "0°11'53.5\" W", contact: "0244662440", email: "info@addpharma4u.com" },
        { name: "Add Pharmacy Gh Ltd (Pigfarm Total Branch)", region: "Greater Accra", district: "Accra Metropolitan District", latitude: "5°36'02.5\" N", longitude: "0°11'50.3\" W", contact: "0501548307", email: "info@addpharma4u.com" },
        { name: "Add Pharmacy Gh Ltd (Movenpick Hotel Branch)", region: "Greater Accra", district: "Central District", latitude: "5°33'14.6\" N", longitude: "0°12'09.2\" W", contact: "0266388182", email: "info@addpharma4u.com" },
        { name: "Obm Pharmacy", region: "Greater Accra", district: "Tema Metropolitan District", latitude: "5°44'11.2\" N", longitude: "0°00'50.2\" W", contact: "0558233960", email: "obm@newcrystalhealth.org" },
        { name: "Ernest May Chemist", region: "Greater Accra", district: "Accra Metropolitan District", latitude: "5°34'47.5\" N", longitude: "0°15'58.7\" W", contact: "0303967865", email: "info@ernestchemist.com" },
        { name: "Zoe Pharmacy", region: "Greater Accra", district: "Ga East Municipal District", latitude: "5°40'02.8\" N", longitude: "0°11'08.5\" W", contact: "0242625998", email: "zoepharmaceuticalslimited@gmail.com" },
        { name: "Trinity Health Hospital", region: "Greater Accra", district: "Ga East Municipal District", latitude: "5°35'11.6\" N", longitude: "0°14'06.7\" W", contact: "0262315061", email: "zellyadjei@gmail.com" },
        { name: "Uretope Eye Care", region: "Greater Accra", district: "Accra Metropolitan District", latitude: "5°38'44.5\" N", longitude: "0°08'44.0\" W", contact: "0205165395", email: "uretopevision@gmail.com" },
        { name: "Afful Lifeline Med. Centre", region: "Greater Accra", district: "Accra Metropolitan District", latitude: "5°35'17.6\" N", longitude: "0°11'41.4\" W", contact: "0548290322", email: "bettyessilfie19@gmail.com" },
        { name: "Mission Clinic", region: "Greater Accra", district: "Accra Metropolitan District", latitude: "5°34'57.2\" N", longitude: "0°12'56.9\" W", contact: "0302220661", email: "" },
        { name: "Integrated Diagnostic Solutions", region: "Greater Accra", district: "Tema Municipal", latitude: "5°41'13.4\" N", longitude: "0°01'54.5\" W", contact: "0544347237", email: "" },
        { name: "Everyday Chemist", region: "Greater Accra", district: "", latitude: "", longitude: "", contact: "", email: "" },
        { name: "Sunshine Healthcare Limited", region: "Greater Accra", district: "Accra Metropolitan District", latitude: "5°36'39.8\" N", longitude: "0°10'38.3\" W", contact: "0263006653", email: "mjbernard56@gmail.com" },
        { name: "Medylife Healthcare Ltd (Clinix)", region: "Greater Accra", district: "Accra Metropolitan District", latitude: "5°34'51.4\" N", longitude: "0°12'56.5\" W", contact: "0555887766", email: "" },
        { name: "The Gordons Optometry Ltd", region: "Greater Accra", district: "Ablekuma West Municipal", latitude: "5°33'46.6\" N", longitude: "0°15'56.0\" W", contact: "0302309316", email: "tgoptometry@gmail.com" },
        { name: "Joefiko Pharmacy", region: "Greater Accra", district: "Tema Metropolitan District", latitude: "5°39'32.2\" N", longitude: "0°00'34.9\" W", contact: "0244642125", email: "" },
        { name: "Raphal Medical Centre", region: "Greater Accra", district: "Tema Metropolitan District", latitude: "5°39'36.7\" N", longitude: "0°01'14.9\" W", contact: "0303301293", email: "" },
        { name: "Family Health Hospital", region: "Greater Accra", district: "Ledzokuku Municipal District", latitude: "5°34'22.6\" N", longitude: "0°06'43.9\" W", contact: "0503326753", email: "" },
        { name: "Danpong Hospital", region: "Greater Accra", district: "Ledzokuku Municipal District", latitude: "5°37'40.9\" N", longitude: "0°05'18.5\" W", contact: "0302811153", email: "" },

    ],
    "Ashanti": [
        { name: "Kuntanase Government Hospital", region: "Ashanti", district: "Bosomtwe District", latitude: "6° 32' 14.9\" N", longitude: "1° 28' 28.0\" W", contact: "0547039070" },
        { name: "Gary Marvin Memorial Hospital", region: "Ashanti", district: "Oforikrom Municipality", latitude: "6° 36' 19.8\" N", longitude: "1° 39' 35.4\" W", contact: "0504386978", email: "kaffgh031973@gmail.com" },
        { name: "Juaso District Hospital", region: "Ashanti", district: "Asante Akim South District", latitude: "6° 35' 32.3\" N", longitude: "1° 07' 16.3\" W", contact: "0244620913", email: "tiertoore@yahoo.com" },
        { name: "Kokofu General Hospital", region: "Ashanti", district: "Bekwai Municipal", latitude: "6.4988° N", longitude: "1.5231° W", contact: "0244272109", email: "kokofugh@gmail.com" },
        { name: "Asonomaso Government Hospital", region: "Ashanti", district: "Kwabre East District", latitude: "6.8328° N", longitude: "1.5120° W", contact: "0244264648", email: "yawggh@yahoo.com" },
        { name: "Adansi North District Hospital", region: "Ashanti", district: "Adansi North District", latitude: "6° 16' 54.5\" N", longitude: "1° 30' 35.8\" W", contact: "0246334981", email: "collins.agyenim@ghs.gov.gh" },
        { name: "True Vine Hospital", region: "Ashanti", district: "Old Tafo Municipal", latitude: "6° 39' 59.6\" N", longitude: "1° 36' 58.7\" W", contact: "054 999 2333", email: "info@truevinehospitalgh.com" },
        { name: "Akropong Government Hospital", region: "Ashanti", district: "Akuapim North District", latitude: "6° 44' 19.7\" N", longitude: "1° 43' 50.5\" W", contact: "0243326479", email: "nicomensah7@gmail.com" },
        { name: "Agona Government Hospital", region: "Ashanti", district: "Agona West Municipal District", latitude: "6° 56' 09.8\" N", longitude: "1° 29' 51.2\" W", contact: "0247002915", email: "benjaminekusi@ghs.gov.gh" },
        { name: "Ahafo Ano North Municipal Hospital", region: "Ashanti", district: "Ahafo Ano North Municipal", latitude: "6° 59' 59.8\" N", longitude: "1° 09' 37.5\" W", contact: "0201293503", email: "tepa.mhar@ghs.gov.gh" },
        { name: "Obuasi Government Hospital", region: "Ashanti", district: "Obuasi Municipal District", latitude: "6.2035° N", longitude: "1.6849° W", contact: "0244010801", email: "mattipeace@yahoo.com" },
        { name: "New Edubiase Government Hospital", region: "Ashanti", district: "Adansi South District", latitude: "6.0544° N", longitude: "1.3936° W", contact: "0244971631", email: "princeinowusu@gmail.com" },
        { name: "Mankranso Government Hospital", region: "Ashanti", district: "Ahafo Ano South District", latitude: "6.8208° N", longitude: "1.8628° W", contact: "0244702588", email: "swash2007@gmail.com" },
        { name: "Ejura Municipal Hospital", region: "Ashanti", district: "Atebubu Amantin District", latitude: "7.3891° N", longitude: "1.3518° W", contact: "0244251650", email: "georgeadugyamfi@8mail.com" },
        { name: "Nyinahin Government Hospital A/R", region: "Ashanti", district: "Atwima Mponua District", latitude: "6° 36' 03.7\" N", longitude: "2° 06' 36.4\" W", contact: "0303959404" },
        { name: "Konongo Hospital", region: "Ashanti", district: "Asante Akim Central District", latitude: "6° 37' 26.7\" N", longitude: "1° 13' 02.6\" W", contact: "0592877407", email: "agyapongsylvester56@gmail.com" },
        { name: "Firstcare Hospital", region: "Ashanti", district: "Adansi Asokwa District", latitude: "6° 40' 23.5\" N", longitude: "1° 38' 49.4\" W", contact: "0244065422", email: "info@firstcare.com" },
        { name: "Ausbury Hospital", region: "Ashanti", district: "Kumasi Metropolitan", latitude: "6.6912° N", longitude: "1.5271° W", contact: "0540796271", email: "ausburyhospital@gmail.com" },
        { name: "Afari Community Hospital Limited", region: "Ashanti", district: "Atwima Nwabiagya Municipality", latitude: "6° 41' 30.5\" N", longitude: "1° 47' 21.5\" W", contact: "0240176502", email: "ach@gmail.com" },
        { name: "A1 Hospital", region: "Ashanti", district: "Bekwai Municipal", latitude: "6.6379° N", longitude: "1.6471° W", contact: "0500941941", email: "info@a1hospitalsgh.com" },
        { name: "Pima Hospital Limited", region: "Ashanti", district: "Kumasi Metropolitan", latitude: "6.7469° N", longitude: "1.5924° W", contact: "0208162372" },
        { name: "St Jude Hospital", region: "Ashanti", district: "Obuasi Municipal District", latitude: "6° 12' 25.4\" N", longitude: "1° 40' 53.6\" W", contact: "0262029695", email: "stjudehospitalobuasi@gmail.com" },
        { name: "Obuasi Ridge Hospital", region: "Ashanti", district: "Obuasi Municipal District", latitude: "6.2004° N", longitude: "1.6930° W", contact: "0503484541", email: "obsridge@gmail.com" },
        { name: "Bryant Mission Hospital", region: "Ashanti", district: "Obuasi Municipal District", latitude: "6.7253° N", longitude: "1.6131° W", contact: "0248119813" },
        { name: "Tafo Government Hospital", region: "Ashanti", district: "Abuakwa North Municipal", latitude: "6° 43' 31.2\" N", longitude: "1° 36' 47.3\" W", contact: "0244362927", email: "tafogovhospitalksi@gmail.com" },
        { name: "Effiduase Government Hospital", region: "Ashanti", district: "Sekyere East", latitude: "6.8476° N", longitude: "1.3935° W", contact: "0240520056F", email: "eghosp@gmail.com" },
        { name: "Nkawie Toase Govt Hospital", region: "Ashanti", district: "Atwima Nwabiagya Municipality", latitude: "6° 39' 52.7\" N", longitude: "1° 48' 38.9\" W", contact: "0543108690", email: "kdetoh@yahoo.com" },
        { name: "Nkenkaasu Government Hospital", region: "Ashanti", district: "Offinso North District", latitude: "7° 19' 09.5\" N", longitude: "1° 54' 12.8\" W", contact: "0546916305", email: "lewiskarley@yahoo.com" },
        { name: "Mamponteng Hospital", region: "Ashanti", district: "Kwabre East District", latitude: "6.7850° N", longitude: "1.5690° W", contact: "0244818303", email: "0244174801" },
        { name: "Mampong Municipal Hospital", region: "Ashanti", district: "Mampong Municipal", latitude: "7.0561° N", longitude: "1.3990° W", contact: "0243973524", email: "mampongmunicipalhospital@gmail.com" },
        { name: "Juaben Government Hospital", region: "Ashanti", district: "Juaben Municipal", latitude: "6° 48' 30.0\" N", longitude: "1° 24' 30.0\" W", contact: "0206301924" },
        { name: "Ejisu Government Hospital", region: "Ashanti", district: "Ejisu Juaben Municipal", latitude: "6° 43' 14.2\" N", longitude: "1° 28' 46.4\" W", contact: "0244596229" },
        { name: "Manhyia District Hospital", region: "Ashanti", district: "Manhyia District", latitude: "6° 42' 09.7\" N", longitude: "1° 36' 44.3\" W", contact: "0244807070", email: "info@manhyiahospital-gov.com" },
        { name: "Bekwai Municipal Hospital", region: "Ashanti", district: "Bekwai Municipal", latitude: "6.4585° N", longitude: "1.5702° W", contact: "0245690366", email: "bekwaimunicipalhospital@yahoo.com" },
        { name: "Sekyedumase Government Hospital", region: "Ashanti", district: "Ejura-Sekyedumase District", latitude: "7° 18' 47.9\" N", longitude: "1° 34' 44.2\" W", contact: "0244447935", email: "roacheampong@yahoo.com" },
        { name: "Afari Community Hospital", region: "Ashanti", district: "Atwima Nwabiagya Municipality", latitude: "6° 41' 30.5\" N", longitude: "1° 47' 21.5\" W", contact: "0240176502", email: "ach@gmail.com" },
        { name: "Obuasi Diagnostic Centre", region: "Ashanti", district: "Obuasi Municipal District", latitude: "6.2044° N", longitude: "1.6849° W", contact: "0246074597", email: "dobuasi@yahoo.com" },
        { name: "Afrancho Health Centre", region: "Ashanti", district: "Bosomtwe District", latitude: "6° 46' 40.6\" N", longitude: "1° 38' 38.2\" W", contact: "0277877874" },
        { name: "Akrofuom Health Centre", region: "Ashanti", district: "Obuasi Municipal District", latitude: "6.1237° N", longitude: "1.6538° W", contact: "0544525453", email: "annorjos2000@yahoo" },
        { name: "Manso Adubia Health Centre", region: "Ashanti", district: "Amanse South District", latitude: "6° 24' 09.1\" N", longitude: "1° 55' 45.9\" W", contact: "0243662711" },
        { name: "Akumadan Health Centre", region: "Ashanti", district: "Offinso North District", latitude: "7.4059° N", longitude: "1.9607° W", contact: "0246322961" },
        { name: "Brofoyedru Health Centre", region: "Ashanti", district: "Adansi North District", latitude: "6.7906° N", longitude: "1.6275° W", contact: "0244725820", email: "oyorteydolo.do@gmail.com" },
        { name: "Beposo Health Centre", region: "Ashanti", district: "Bosomtwe District", latitude: "6° 59' 07.7\" N", longitude: "1° 21' 19.4\" W", contact: "0208081248", email: "0244245972", email: "beposohealthcentre@yahoo.com" },
        { name: "Adidwan Health Centre", region: "Ashanti", district: "Mampong Municipal", latitude: "7° 15' 05.6\" N", longitude: "1° 24' 10.1\" W", contact: "0244181568" },
        { name: "Asokwa Health Centre", region: "Ashanti", district: "Adansu North District", latitude: "6.2392° N", longitude: "1.4992° W", contact: "0249365525", email: "edjamorla@yahoo.com" },
        { name: "Ayeduase Health Centre", region: "Ashanti", district: "Kumasi Metropolitan", latitude: "6° 40' 33.0\" N", longitude: "1° 33' 39.7\" W", contact: "0244239986" },
        { name: "Offinso Health Centre", region: "Ashanti", district: "Adansi South District", latitude: "6° 56' 17\" N", longitude: "1° 40' 30\" W", contact: "0243806475", email: "stolazzy@gmail.com" },
        { name: "Government Health Centre Ahenkro", region: "Ashanti", district: "Afigya-Kwabre South District", latitude: "6° 52' 18.1\" N", longitude: "1° 38' 28.9\" W", contact: "0200122845", email: "anyomiraph@gmail.com" },
        { name: "Kwanwoma Health Centre", region: "Ashanti", district: "Atwima Kwanwoma District", latitude: "6° 37' 43.3\" N", longitude: "1° 38' 06.7\" W", contact: "0572260927" },
        { name: "Aboaso Health Centre", region: "Ashanti", district: "Kwabre District", latitude: "6° 48' 29.4\" N", longitude: "1° 32' 22.8\" W", contact: "0208801472" },
        { name: "Ahenema Kokoben Health Centre", region: "Ashanti", district: "Atwima Kwanwoma District", latitude: "6° 37' 34.6\" N", longitude: "1° 38' 59.6\" W", contact: "0243375778" },
        { name: "Jamasi Health Centre", region: "Ashanti", district: "Atiwa West District", latitude: "6° 58' 41.5\" N", longitude: "1° 28' 20.3\" W", contact: "0245226565" },
        { name: "Jachie Health Centre", region: "Ashanti", district: "Bosomtwe District", latitude: "6.5659° N", longitude: "1.5208° W", contact: "0244247534", email: "kwakuboadu@yahoo.com" },
        { name: "Asiwa Health Centre", region: "Ashanti", district: "Bosome Freho District", latitude: "6° 25' 23.8\" N", longitude: "1° 20' 04.3\" W", contact: "0277290674", email: "0242370996" },
        { name: "Prima Medical Centre", region: "Ashanti", district: "Ashanti District", latitude: "6° 39' 03.4\" N", longitude: "1° 37' 35.2\" W", contact: "0508618903" },
        { name: "Rabito Clinic", region: "Ashanti", district: "Lindador Pharmacy", latitude: "", longitude: "", contact: "0501634173", email: "iagbesse@rabitoclinic.com" },
        { name: "Drobonso RCH Clinic", region: "Ashanti", district: "Kwabre East", latitude: "7° 03' 28.1\" N", longitude: "1° 07' 29.9\" W", contact: "0242908917", email: "abu.awfo@yahoo.com" },
        { name: "Bomso Clinic", region: "Ashanti", district: "Oforikrom Municipality", latitude: "6.6846° N", longitude: "1.5787° W", contact: "0322496283", email: "iagbesse@rabitoclinic.com" },
        { name: "Rabito Clinic", region: "Ashanti", district: "111 Melcom Rd, Kumasi", latitude: "6° 39' 30.4\" N", longitude: "1° 37' 10.0\" W", contact: "244702640", email: "info@rabitoclinic.com" },
        { name: "Manso Nkwanta Polyclinic", region: "Ashanti", district: "Amansie West", latitude: "6° 27' 59.3\" N", longitude: "1° 52' 35.8\" W", contact: "0508450043", email: "kyereboro@gmail.com" },
        { name: "Kumawu Polyclinic", region: "Ashanti", district: "Sekyere Kumawu", latitude: "6.9076° N", longitude: "1.2685° W", contact: "0501336508", email: "skdeoffice@gmail.com" },
        { name: "Abuakwa Polyclinic", region: "Ashanti", district: "Atwima Nwabiagya Municipality", latitude: "6.7013° N", longitude: "1.7153° W", contact: "0208137761" },
        { name: "Asuofua Polyclinic", region: "Ashanti", district: "Atwima Nwabiagya Municipality", latitude: "6° 46' 07.7\" N", longitude: "1° 41' 04.9\" W", contact: "0248735853" },
        { name: "Quality Health Care", region: "Ashanti", district: "Sekyere Kumawu", latitude: "6° 42' 16.0\" N", longitude: "1° 37' 25.3\" W", contact: "0277701452" },
        { name: "Makenzi Health Services", region: "Ashanti", district: "Ahinsan Estate District", latitude: "6° 41' 11.2\" N", longitude: "1° 35' 25.6\" W", contact: "0571110160", email: "0244460343" },
        { name: "Briteaid", region: "Ashanti", district: "Kumasi Metropolitan", latitude: "6° 41' 07.1\" N", longitude: "1° 37' 03.2\" W", contact: "0200111894", email: "info@briteaidhealth.org" },
        { name: "Bandy Chemist", region: "Ashanti", district: "Asuogyaman District", latitude: "6° 41' 28.3\" N", longitude: "1° 37' 16.7\" W", contact: "0202110216" },
        { name: "Saqs Pharmacy", region: "Ashanti", district: "Kumasi Metropolitan", latitude: "6° 40' 26.2\" N", longitude: "1° 36' 27.7\" W", contact: "0269123965", email: "saqspharm1@gmail.com" },
        { name: "Rapheka Pharmacy Limited", region: "Ashanti", district: "Kumasi", latitude: "5° 43' 09.1\" N", longitude: "0° 12' 05.2\" W", contact: "0244226160" },
        { name: "Juliponia Pharmacy", region: "Ashanti", district: "Kumasi", latitude: "6° 41' 13.9\" N", longitude: "1° 34' 26.2\" W", contact: "0546795970" },
        { name: "Estel Pharmacy", region: "Ashanti", district: "Obuasi Municipal District", latitude: "6° 11' 42.2\" N", longitude: "1° 41' 39.1\" W", contact: "0596912678", email: "oforimensa@gmail.com" },
        { name: "Bandy Chemist", region: "Ashanti", district: "Asuogyaman District", latitude: "6° 41' 28.3\" N", longitude: "1° 37' 16.7\" W", contact: "0202110216", email: "bandychemistenquires@gmail.com" },
        { name: "Bellview Eye Care", region: "Ashanti", district: "Obuasi Municipal District", latitude: "6° 12' 13.3\" N", longitude: "1° 40' 38.8\" W", contact: "0322022217" },
        { name: "Oak Specialist Hospital", region: "Ashanti", district: "Bekwai Municipal", latitude: "6° 39' 47.9\" N", longitude: "1° 38' 22.9\" W", contact: "0509760659", email: "info@oakspecialisthospital.com" },
        { name: "Kleenmed Pharmacy", region: "Ashanti", district: "Kumasi Metropolitan", latitude: "5° 06' 43.4\" N", longitude: "1° 14' 14.8\" W", contact: "0243-159968", email: "kleenmed.pharm@gmail.com" },
        { name: "Rapheka Pharmacy", region: "Ashanti", district: "Obuasi Municipal District", latitude: "", longitude: "", contact: "0244226160" },
        { name: "Nandom Health Centre", region: "Ashanti", district: "Atwima-Nwabiagya District", latitude: "", longitude: "", contact: "0244140644", email: "fallenoma@gmail.com" },
        { name: "Nyinahih Government Hospital A/R", region: "Ashanti", district: "Atwima Mponua District", latitude: "6° 36' 03.7\" N", longitude: "2° 06' 36.4\" W", contact: "0244055265", email: "agyenporchrist@yahoo.com" },
        { name: "Lizmok Pharmacy Limited", region: "Ashanti", district: "Obuasi Municipal District", latitude: "6° 11' 58.9\" N", longitude: "1° 40' 46.9\" W", contact: "0244195065", email: "info@Lizmokpharmacy.com" },

    ],
    "Western": [
        { name: "Prestea Government Hospital", region: "Western", district: "Prestea-Huni Valley District", latitude: "5° 25' 57.2\" N", longitude: "2° 08' 54.8\" W", contact: "0247756333", email: "N/A" },

        { name: "Nana Hima Dekyi Hospital", region: "Western", district: "Ahanta West District", latitude: "4° 48' 21.0\" N", longitude: "1° 57' 22.8\" W", contact: "0248251980", email: "N/A" },

        { name: "Kwesimetwim Government Hospital", region: "Western", district: "Sekondi-Takoradi Metropolitan", latitude: "4° 54' 46.2\" N", longitude: "1° 46' 55.6\" W", contact: "0242314754", email: "N/A" },

        { name: "Begoso Government Hospital", region: "Western", district: "Prestea-Huni Valley District", latitude: "5° 33' 16.0\" N", longitude: "1° 59' 57.5\" W", contact: "0249487342", email: "N/A" },

        { name: "Apinto Government Hospital", region: "Western", district: "Sekondi-Takoradi Metropolitan", latitude: "5° 18' 21.0\" N", longitude: "1° 59' 10.3\" W", contact: "0243334801", email: "N/A" },

        { name: "Half Asini Government Hospital", region: "Western", district: "Ahanta West District", latitude: "5° 03' 03.8\" N", longitude: "2° 52' 48.7\" W", contact: "0554085643", email: "samuelkojoasare@gmail.com" },

        { name: "Takoradi Hospital", region: "Western", district: "Sekondi-Takoradi Metropolitan", latitude: "4° 53' 08.1\" N", longitude: "1° 45' 07.3\" W", contact: "0246544035", email: "N/A" },

        { name: "Strafford Clinic", region: "Western", district: "Sekondi-Takoradi Metropolitan", latitude: "4° 58' 38.3\" N", longitude: "1° 43' 02.7\" W", contact: "0208282611", email: "N/A" },

        { name: "Adombi Dental", region: "Western", district: "Sekondi-Takoradi Metropolitan", latitude: "4° 54' 03.8\" N", longitude: "1° 45' 55.6\" W", contact: "0543341355", email: "oppongkwadwolive@gmail.com" },

        { name: "Oasis Medical Consult", region: "Western", district: "Sekondi-Takoradi Metropolitan", latitude: "4° 56' 32.7\" N", longitude: "1° 45' 05.2\" W", contact: "0249129050", email: "info@oasismedicalconsult.com" },

        { name: "Benedict Hospital", region: "Western", district: "Shama District", latitude: "4° 59' 04.9\" N", longitude: "1° 40' 56.3\" W", contact: "0246787814", email: "winfkwadwo@yahoo.com" },

        { name: "St Paul Dental Clinic", region: "Western", district: "Sekondi-Takoradi Metropolitan", latitude: "4° 54' 08.3\" N", longitude: "1° 45' 31.7\" W", contact: "0503346433", email: "selormortho@gmail.com" },

        { name: "Efia Nkwanta Hospital", region: "Western", district: "Sekondi-Takoradi Metropolitan", latitude: "4° 55' 26.2\" N", longitude: "1° 44' 40.4\" W", contact: "0209209764", email: "asendark@yahoo.co.uk" },

        { name: "Takoradi High Street Clinic", region: "Western", district: "Sekondi-Takoradi Metropolitan", latitude: "4° 53' 00.8\" N", longitude: "1° 45' 05.6\" W", contact: "0235559054", email: "thsclinic@ymail.com" },

        { name: "Delcielo Opticans", region: "Western", district: "Sekondi-Takoradi Metropolitan", latitude: "4° 53' 55.3\" N", longitude: "1° 45' 46.1\" W", contact: "0246546124", email: "freddisonash@gmail.com" },

        { name: "Abukwa Pharmacy", region: "Western", district: "Sekondi-Takoradi Metropolitan", latitude: "5° 35' 28.3\" N", longitude: "0° 19' 11.3\" W", contact: "0244111923", email: "abuakwa.pharmacy@yahoo.com" },

        { name: "Kabmore Pharmacy", region: "Western", district: "Sekondi-Takoradi Metropolitan", latitude: "4° 53' 17.0\" N", longitude: "1° 45' 37.1\" W", contact: "0261280160", email: "rexarth49.ra@gmail.com" },

        { name: "Atlantic Chemist", region: "Western", district: "Sekondi-Takoradi Metropolitan", latitude: "4° 53' 15.7\" N", longitude: "1° 45' 15.1\" W", contact: "0243688057", email: "aclchemists78@gmail.com" },

        { name: "Qui-Wal Hospital", region: "Western", district: "Sekondi-Takoradi Metropolitan", latitude: "4° 54' 16.6\" N", longitude: "1° 46' 37.9\" W", contact: "N/A", email: "N/A" },

        { name: "Nagel Memorial SDA Hospital", region: "Western", district: "Sekondi-Takoradi Metropolitan", latitude: "4° 54' 06.1\" N", longitude: "1° 45' 59.6\" W", contact: "026-5583293", email: "nagelhospital@gmail.com" },

        { name: "Bethel Methodist Medical Centre", region: "Western", district: "Sekondi-Takoradi Metropolitan", latitude: "4° 53' 35.0\" N", longitude: "1° 45' 33.1\" W", contact: "0262981771", email: "bmmctadi@gmail.com" },

        { name: "Vision Crafters", region: "Western", district: "Sekondi-Takoradi Metropolitan", latitude: "4° 53' 14.8\" N", longitude: "1° 45' 00.7\" W", contact: "0243529567", email: "vcopticianx@gmail.com" },

        { name: "Imperial Eye Care", region: "Western", district: "Sekondi-Takoradi Metropolitan", latitude: "4° 56' 16.6\" N", longitude: "1° 45' 21.4\" W", contact: "0243282775", email: "info@imperialeye.com" },

        { name: "Westview Medical Centre", region: "Western", district: "Sekondi-Takoradi Metropolitan", latitude: "4° 53' 53.5\" N", longitude: "1° 47' 05.8\" W", contact: "0209899589", email: "westviewmedicalcentre@gmail.com" },

        { name: "UQ Specialist Medical Services", region: "Western", district: "Sekondi-Takoradi Metropolitan", latitude: "4° 55' 17.6\" N", longitude: "1° 46' 14.5\" W", contact: "0244-534553", email: "uQspecialists@ymail.com" },

        { name: "Essikado Hospital", region: "Western", district: "Sekondi-Takoradi Metropolitan", latitude: "4° 56' 44.5\" N", longitude: "1° 42' 52.4\" W", contact: "02098089461", email: "N/A" },

        { name: "New Crystal Medical Centre", region: "Western", district: "Sekondi-Takoradi Metropolitan", latitude: "4° 55' 19.4\" N", longitude: "1° 46' 23.0\" W", contact: "0312002331", email: "eugeniagyekeadwoa@gmail.com" },

        { name: "Afful Lifeline Med.Centre", region: "Western", district: "Shama District", latitude: "N/A", longitude: "N/A", contact: "0548290322", email: "ekuaabuduba@gmail.com" },

        { name: "Agona Nkwanta Polyclinic", region: "Western", district: "Ahanta District", latitude: "4° 53' 25.3\" N", longitude: "1° 57' 52.3\" W", contact: "0548638795", email: "deawsome1@gmail.com" },

        { name: "Rabito Clinic", region: "Western", district: "Sekondi-Takoradi Metropolitan", latitude: "4° 53' 49.9\" N", longitude: "1° 45' 52.0\" W", contact: "0559740524", email: "iagbesse@rabitoclinic.com" },

        { name: "Takoradi Dis Clinic", region: "Western", district: "Sekondi-Takoradi Metropolitan", latitude: "4° 53' 20.2\" N", longitude: "1° 45' 33.5\" W", contact: "0552006666", email: "achimdavid9089@gmail.com" },

        { name: "Twin City Clinic", region: "Western", district: "Sekondi-Takoradi Metropolitan", latitude: "4° 53' 18.3\" N", longitude: "1° 45' 14.2\" W", contact: "0312022282", email: "N/A" },

        { name: "Takoradi High Street Clinic", region: "Western", district: "Sekondi-Takoradi Metropolitan", latitude: "4° 53' 00.8\" N", longitude: "1° 45' 05.6\" W", contact: "0312023769", email: "N/A" },

        { name: "PH Laboratory services", region: "Western", district: "Sekondi-Takoradi Metropolitan", latitude: "4° 55' 47.8\" N", longitude: "1° 44' 30.5\" W", contact: "0255768976", email: "N/A" },

        { name: "Doctors In Service", region: "Western", district: "Sekondi-Takoradi Metropolitan", latitude: "4° 53' 20.2\" N", longitude: "1° 45' 33.5\" W", contact: "0579290027", email: "info@doctorsinservice.com" },

        { name: "Solar Eye Center", region: "Western", district: "Sekondi-Takoradi Metropolitan", latitude: "4° 53' 44.0\" N", longitude: "1° 45' 53.8\" W", contact: "0312024832", email: "solaropticalsltd@yahoo.com" },

        { name: "Esikado Hospital", region: "Western", district: "Sekondi-Takoradi Metropolitan", latitude: "4° 56' 44.5\" N", longitude: "1° 42' 52.4\" W", contact: "0549052590", email: "N/A" },

        { name: "Oasis Medical Centre", region: "Western", district: "Sekondi-Takoradi Metropolitan", latitude: "4° 56' 32.8\" N", longitude: "1° 45' 05.2\" W", contact: "0249129050", email: "info@oasismedicalconsult.com" },

        { name: "St. Benedict Hospital", region: "Western", district: "Shama District", latitude: "4° 59' 04.9\" N", longitude: "1° 40' 56.3\" W", contact: "02445448866", email: "INFO@benedicthospital.com" },

        { name: "Vision Crafters", region: "Western", district: "Sekondi-Takoradi Metropolitan", latitude: "4° 53' 14.8\" N", longitude: "1° 45' 00.7\" W", contact: "0243529567", email: "N/A" },

        { name: "Axim Government Hospital", region: "Western", district: "Nzama East Municipal District", latitude: "4° 51' 55.1\" N", longitude: "2° 14' 18.8\" W", contact: "0559096266", email: "N/A" },

        { name: "Tarwa Old Government Hospital", region: "Western", district: "Tarwa-Nsuaem Municipal District", latitude: "5° 18' 20.9\" N", longitude: "1° 59' 10.3\" W", contact: "0243334801", email: "N/A" },

        { name: "Tarwa Municipal Hospital", region: "Western", district: "Tarwa-Nsuaem Municipal District", latitude: "5° 18' 18.7\" N", longitude: "1° 58' 36.1\" W", contact: "0246795437", email: "N/A" },

        { name: "Wassa Akropong Government Hospital", region: "Western", district: "Wassa Amenfi East Municipal District", latitude: "5° 46' 52.7\" N", longitude: "2° 05' 00.8\" W", contact: "0246305405", email: "N/A" },

        { name: "St.Paul Dental Clinic", region: "Western", district: "Sekondi-Takoradi Metropolitan", latitude: "4° 54' 08.3\" N", longitude: "1° 45' 31.7\" W", contact: "0204609018", email: "selormortho@gmail.com" }

    ],
    "Central": [
        { name: "Ankaful Leprosy/General Hospital", region: "Central", district: "Cape Coast Metropolitan", latitude: "5° 09' 02.9\" N", longitude: "1° 19' 04.1\" W", contact: "0244781289", email: "gadanso@yahoo.com" },
        { name: "Cape Coast Metropolitan Hospital", region: "Central", district: "Cape Coast Metropolitan", latitude: "5.1021° N", longitude: "1.2597° W", contact: "0244844977/0206558408", email: "adolfolk@yahoo.com" },
        { name: "Gomoa Obuasi Health Centre", region: "Central", district: "Gomoa East District", latitude: "5° 26' 07.6\" N", longitude: "0° 42' 55.1\" W", contact: "024470853", email: "millicentkotey09@gmail.com" },
        { name: "Akonfudi Health Centre", region: "Central", district: "Assin Central Municipal District", latitude: "5° 49' 46.8\" N", longitude: "1° 18' 38.2\" W", contact: "0545463641", email: "george.bempah@gmail.com" },
        { name: "Jukwa Health Centre", region: "Central", district: "Twifo-Ati Mokwa District", latitude: "5.2596° N", longitude: "1.3330° W", contact: "0243334633", email: "fishmorefire78@gmail.com" },
        { name: "Abura Dunkwa District Hospital", region: "Central", district: "Gomoa East District", latitude: "5° 20' 16.4\" N", longitude: "1° 10' 16.7\" W", contact: "546630622", email: "martindaiter92@gmail.com" },
        { name: "Bawjiase Polyclinic", region: "Central", district: "Awutu Senya District", latitude: "5° 41' 06.2\" N", longitude: "0° 33' 06.5\" W", contact: "0245761689", email: "abrantiekomla@gmail.com" },
        { name: "Winneba Municipal Hospital", region: "Central", district: "Effutu Municipal District", latitude: "5.3433° N", longitude: "0.6231° W", contact: "0244749072", email: "ielzie@gmail.com" },
        { name: "Agona Swedru Municipal Hospital", region: "Central", district: "Agona West Municipal District", latitude: "5° 32' 05.8\" N", longitude: "0° 41' 33.7\" W", contact: "0243884492", email: "trapezium19@gmail.com" },
        { name: "Awutu Health Centre", region: "Central", district: "Awutu Senya District", latitude: "5° 30' 46.1\" N", longitude: "0° 30' 52.9\" W", contact: "0553066573", email: "rossyksk@gmail.com" },
        { name: "Senya Polyclinic", region: "Central", district: "Awutu Senya West District", latitude: "5° 23' 25.5\" N", longitude: "0° 29' 50.5\" W", contact: "0544745587/024270730", email: "copious13@gmail.com" },
        { name: "New Market Health Centre", region: "Central", district: "Gomoa East District", latitude: "44.0455° N", longitude: "79.4688° W", contact: "0246372303", email: "mikioteh18@gmail.com" },
        { name: "Assin Fosu Polyclinic", region: "Central", district: "Assin Central Municipal", latitude: "5° 41' 58.9\" N", longitude: "1° 16' 40.7\" W", contact: "0241414239", email: "emmadonkor883@gmail.com" },
        { name: "Assin Manso Health Centre", region: "Central", district: "Denkyembour District", latitude: "5.5241° N", longitude: "1.1677° W", contact: "0240586202" },
        { name: "Akoti Health Care", region: "Central", district: "Assin Central Municipal", latitude: "5.7737° N", longitude: "1.2450° W", contact: "0242774026", email: "davidkodua1910@gmail.com" },
        { name: "Abura Medical Lab", region: "Central", district: "Abura-Asebu-Kwamankese District", latitude: "5.1289° N", longitude: "1.2746° W", contact: "03321-33967" },
        { name: "Kingsway Pharmacy", region: "Central", district: "Awutu Senya East District", latitude: "5.1068° N", longitude: "1.2425° W", contact: "0241870001" },
        { name: "Queens Street Pharmacy", region: "Central", district: "Cape Coast Metropolitan", latitude: "41.1227° S", longitude: "175.0737° E", contact: "0244629856", email: "qsprichmond@xtra.co.nz" },
        { name: "Klimovic Medical Centre", region: "Central", district: "Effutu Municipal District", latitude: "5° 21' 57.8\" N", longitude: "0° 38' 14.3\" W", contact: "027-8076221" },
        { name: "Oak Tree Medical", region: "Central", district: "Cape Coast Metropolitan", latitude: "5.1149° N", longitude: "1.2745° W", contact: "050-5785086", email: "oaktreemediservices.org" },
        { name: "DIS Clinic", region: "Central", district: "Upper Denkyira West District", latitude: "5.1309° N", longitude: "1.2757° W", contact: "0549441896", email: "info@doctorsinservice.com" },
        { name: "Mankessim Health Centre", region: "Central", district: "Mfantsiman Municipal", latitude: "", longitude: "", contact: "244135366", email: "qessiaba@gmail.com" },
        { name: "Awisem Health Centre", region: "Central", district: "Assin Central Municipal District", latitude: "5° 36' 57.5\" N", longitude: "1° 32' 34.6\" W", contact: "2450551916" },
        { name: "Subin Health Center", region: "Central", district: "Upper Denkyira West District", latitude: "6° 12' 01.5\" N", longitude: "2° 02' 33.5\" W", contact: "0243773788" },
        { name: "Breman Asikuma Health Centre", region: "Central", district: "Asikuma District", latitude: "5° 35' 25.2\" N", longitude: "0° 59' 40.1\" W", contact: "0247731456/0593646915" },
        { name: "Diaso Health Center", region: "Central", district: "Upper Denkyira West District", latitude: "6° 09' 12.7\" N", longitude: "2° 09' 03.1\" W", contact: "02467365541" },
        { name: "Rabito Clinic", region: "Central", district: "Effutu Municipal District", latitude: "", longitude: "", contact: "0593854713", email: "info@rabitoclinic.com" },
        { name: "Jamra Polyclinic", region: "Central", district: "Asikuma District", latitude: "5.6589° N", longitude: "1.0109° W", contact: "0553251422/0555210313" },
        { name: "Twifo Praso Polyclinic", region: "Central", district: "Twifo District", latitude: "5° 36' 39\" N", longitude: "1° 32' 56\" W", contact: "209730246" },
        { name: "Elmina Polyclinic", region: "Central", district: "Edina Municipal District", latitude: "5° 05' 54.9\" N", longitude: "1° 20' 54.1\" W", contact: "501412294", email: "elimina.pccr@ghs" },
        { name: "Dawurampong Polyclinic", region: "Central", district: "Gomoa East District", latitude: "6° 26' 05.8\" N", longitude: "0° 12' 45.2\" E", contact: "0245152506" },
        { name: "New Crystal Hospital", region: "Central", district: "Effutu Municipal District", latitude: "", longitude: "", contact: "0544314384", email: "info@newcrystalhealth.org" },
        { name: "Baiden Ghartey Memorial Hospital", region: "Central", district: "Cape Coast Metropolitan", latitude: "5° 07' 30.7\" N", longitude: "1° 16' 30.7\" W", contact: "0332133332", email: "baidengharteyhospital@gmail.com" },
        { name: "New Market Health Centre", region: "Central", district: "Awutu Senya District", latitude: "5° 33' 17.2\" N", longitude: "0° 26' 01.8\" W", contact: "0505568783", email: "info@york.ca" },
        { name: "Rabito Clinic", region: "Central", district: "Awutu Senya District", latitude: "", longitude: "", contact: "0593854713", email: "info@rabitoclinic.com" },
        { name: "Finney Hospital & Fertility Center", region: "Central", district: "Awutu Senya East District", latitude: "5° 32' 53.7\" N", longitude: "0° 21' 31.1\" W", contact: "0302950092" }
    ],
    "Volta": [
        { name: "Catholic Hospital Battor", region: "Volta", district: "Central Tongu District", latitude: "6°04'06.8\" N", longitude: "0°24'05.6\" E", contact: "0242172284", email: "battorcervicalcentre@gmail.com" },
        { name: "Wli Central Health Centre", region: "Volta", district: "Hohoe District", latitude: "7°06'58.3\" N", longitude: "0°35'11.6\" E", contact: "0547737548", email: "" },
        { name: "Volta Municipal Hospital, Hohoe", region: "Volta", district: "Hohoe District", latitude: "7°09'25.4\" N", longitude: "0°28'29.3\" E", contact: "0244973608", email: "" },
        { name: "Waya Health Centre", region: "Volta", district: "Adaklu District", latitude: "6°26'53.0\" N", longitude: "0°37'26.6\" E", contact: "0541286121/0244058495", email: "" },
        { name: "Margret Marquart Hospital", region: "Volta", district: "Kpando Municipal District", latitude: "6°59'58.4\" N", longitude: "0°18'08.8\" E", contact: "362350136", email: "info.mmch1960@gmail.com" },
        { name: "Ziope Health Centre", region: "Volta", district: "Agotime-Ziope District", latitude: "6°26'11.2\" N", longitude: "0°44'17.5\" E", contact: "0247497333", email: "" },
        { name: "Ho Teaching Hospital", region: "Volta", district: "Ho Municipal District", latitude: "6°36'08.3\" N", longitude: "0°29'04.0\" E", contact: "0362027319", email: "info@hth.gov.gh" },
        { name: "Penyi Health Centre", region: "Volta", district: "Ketu North Municipal District", latitude: "6°13'08.6\" N", longitude: "1°02'15.2\" E", contact: "0246201453", email: "melodypenians@gmail.com" },
        { name: "Anfoega Catholic Hospital", region: "Volta", district: "North Dayi District", latitude: "6°53'09.8\" N", longitude: "0°16'42.4\" E", contact: "0501629250", email: "anfoegacatholichospital@yahoo.com" },
        { name: "Juapong Health Centre", region: "Volta", district: "North Tongu District", latitude: "6°14'52.6\" N", longitude: "0°09'06.5\" E", contact: "0241244170", email: "joycehlodze@gmail.com" },
        { name: "Anloga Health Centre", region: "Volta", district: "Keta Municipal District", latitude: "5°47'30.5\" N", longitude: "0°52'55.9\" E", contact: "0541099999", email: "hpe.kordorwu@ghs.gov.gh" },
        { name: "Tegbi Health Centre", region: "Volta", district: "Keta Municipal District", latitude: "5°50'57.1\" N", longitude: "0°58'21.2\" E", contact: "0243982756", email: "amegahkafui@gmail.com" },
        { name: "Kpando Health Centre", region: "Volta", district: "Kpando Municipal District", latitude: "6°59'35.0\" N", longitude: "0°17'24.2\" E", contact: "0248803825", email: "" },
        { name: "Kpeve Health Centre", region: "Volta", district: "Afadjato South District", latitude: "6°40'41.5\" N", longitude: "0°18'51.1\" E", contact: "0246984643", email: "christopher.mbabil@ghs.gov.gh" },
        { name: "Keta Municipal Hospital", region: "Volta", district: "Keta Municipal District", latitude: "5°53'17.5\" N", longitude: "0°58'57.2\" E", contact: "0554089822", email: "ketahosp@yahoo.com" },
        { name: "Ho Royal Hospital Limited", region: "Volta", district: "Ho Municipal District", latitude: "6°37'50.9\" N", longitude: "0°28'39.7\" E", contact: "0362095151", email: "royalhospital@yahoo.com" },
        { name: "Ve Golokuati Health Centre", region: "Volta", district: "Afadjato South District", latitude: "7°00'02.5\" N", longitude: "0°26'15.2\" E", contact: "0246984643", email: "christopher.mbabil@ghs.gov.gh" },
        { name: "Peki Government Hospital", region: "Volta", district: "South Dayi District", latitude: "6°32'03.1\" N", longitude: "0°13'34.7\" E", contact: "0243337943/0245428909", email: "" },
        { name: "Catholic Hospital Battor", region: "Volta", district: "Central Tongu District", latitude: "6°04'06.8\" N", longitude: "0°24'05.6\" E", contact: "0246953704", email: "pearl.bedzo@yahoo.com" },
        { name: "Bol Medical Services", region: "Volta", district: "Ho Municipal District", latitude: "6°36'20.8\" N", longitude: "0°28'09.1\" E", contact: "0244245087", email: "boatengharry988@yahoo.com" },
        { name: "Dzolokpuita Health Centre", region: "Volta", district: "Ho West District", latitude: "6°47'05.3\" N", longitude: "0°26'41.3\" E", contact: "0243166614", email: "" },
        { name: "Dzologbogame Health Centre", region: "Volta", district: "Ho West District", latitude: "6°46'31.6\" N", longitude: "0°28'36.5\" E", contact: "0245463170", email: "" },
        { name: "Vakpo Health Centre", region: "Volta", district: "North Dayi District", latitude: "6°51'24\" N", longitude: "0°16'56\" E", contact: "0557785534", email: "" },
        { name: "Fodome Helu Health Centre", region: "Volta", district: "Hohoe District", latitude: "7.0742° N", longitude: "0.5071° E", contact: "0540822590", email: "" },
        { name: "Cederville hospital", region: "Volta", district: "Hohoe District", latitude: "7°07'46.9\" N", longitude: "0°27'27.2\" E", contact: "0541249464", email: "info@cedarvillehospital.com" },
        { name: "Foresight Medical Centre", region: "Volta", district: "Ho Municipal District", latitude: "6°37'00.5\" N", longitude: "0°27'48.8\" E", contact: "0501480982", email: "" },
        { name: "Ho Polyclinic", region: "Volta", district: "Ho Municipal District", latitude: "6°36'09.7\" N", longitude: "0°29'33.7\" E", contact: "0204277225", email: "konkadaniel@yahoo.com" },
        { name: "Rabito Clinic", region: "Volta", district: "Keta South District", latitude: "6°37'05.9\" N", longitude: "0°28'10.9\" E", contact: "0596913667", email: "iagbesse@rabitoclinic.com" },
        { name: "Adidome Government Hospital", region: "Volta", district: "Central Tongu District", latitude: "6°04'20.3\" N", longitude: "0°29'54.4\" E", contact: "0244040158", email: "samadley@yahoo.com" },
        { name: "Have Health Centre", region: "Volta", district: "Afadjato South District", latitude: "6°45'28.7\" N", longitude: "0°21'39.8\" E", contact: "0246984643", email: "christopher.mbabil@gmail.com" },
        { name: "Cerdarville Hospital", region: "Volta", district: "Hohoe District", latitude: "7°07'46.9\" N", longitude: "0°27'27.2\" E", contact: "0541249464", email: "hosei5176@gmail.com" },
        { name: "Mater Ecclesiae Hospital", region: "Volta", district: "Ho Municipal District", latitude: "6°33'59.7\" N", longitude: "0°24'34.8\" E", contact: "0248497426", email: "yayranaayo@gmail.com" },
        { name: "New Berg Hospital", region: "Volta", district: "Keta South District", latitude: "", longitude: "", contact: "", email: "" }
    ],
    'Bono': [
        { name: "Greenlight Pharmacy", region: "Bono", district: "Sunyani Municipal", latitude: "7°20'11.2\" N", longitude: "2°19'41.0\" W", contact: "0209944881", email: "" },
        { name: "Nsoatre Polyclinic", region: "Bono", district: "Sunyani West Municipal", latitude: "7°23'56.6\" N", longitude: "2°27'47.0\" W", contact: "0554124547", email: "joeeesh79@yahoo.com" },
        { name: "Dormaa West District Hospital", region: "Bono", district: "Dormaa West District", latitude: "7°01'08.2\" N", longitude: "3°03'34.4\" W", contact: "0243721481/0555146588", email: "dwdhosp2019@yahoo.com" },
        { name: "Dormaa East District Hospital", region: "Bono", district: "Dormaa East District", latitude: "7°18'22.1\" N", longitude: "2°41'57.9\" W", contact: "0243182449", email: "mamekwenuba@gmil.com" },
        { name: "Jinijini Health Centre", region: "Bono", district: "Berekum West District", latitude: "7°26'47.5\" N", longitude: "2°38'47.2\" W", contact: "0200804325", email: "richmonddwaase@gmail.com" },
        { name: "Drobo Polyclinic", region: "Bono", district: "Jaman South District", latitude: "5°35'29.7\" N", longitude: "0°12'43.9\" E", contact: "024272760", email: "barimah41@gmail.com" },
        { name: "Sampa Government Hospital", region: "Bono", district: "Jaman North District", latitude: "7.9562° N", longitude: "2.6945° W", contact: "0249321069", email: "theresa.botchway@yahoo.com" },
        { name: "Banda Nhenkro Health Centre", region: "Bono", district: "Banda District", latitude: "8°09'48.5\" N", longitude: "2°21'37.2\" W", contact: "0240463851", email: "" },
        { name: "Tain District Hospital Nsawkaw", region: "Bono", district: "Tain District", latitude: "", longitude: "", contact: "0245115888", email: "richardasamoah200@gmail.com" },
        { name: "Wenchi Health Centre", region: "Bono", district: "Wenchi Municipal", latitude: "7.7420° N", longitude: "2.1009° W", contact: "0554557876", email: "" },
        { name: "Sena West District Hospital", region: "BONO EAST", district: "Sena West District", latitude: "7°43'49.7\" N", longitude: "0°41'08.2\" W", contact: "0240514872", email: "osmanabakere@gmail.com" },
        { name: "Municipal Hospital Atebubu", region: "BONO EAST", district: "Atebubu-Amantin Municipal", latitude: "7.7482° N", longitude: "0.9821° W", contact: "0245953472", email: "alhassan.mohammud936@gmail.com" },
        { name: "Prang Health Centre", region: "BONO EAST", district: "Pru West District", latitude: "7.9947° N", longitude: "0.8893° W", contact: "0547512743", email: "vidakunkuri@gmail.com" },
        { name: "Busunya Polyclinic", region: "BONO EAST", district: "Nkoranza North District", latitude: "7°41'05.3\" N", longitude: "1°39'28.1\" W", contact: "0553514937", email: "buckmanebenezer6@gmail.com" },
        { name: "Africa Libera Health Centre", region: "BONO EAST", district: "Nkoranza District", latitude: "7°34'01.9\" N", longitude: "1°41'55.8\" W", contact: "0242673294", email: "tampuoriangboug9@gmail.com" },
        { name: "Nkoranza Health Centre", region: "BONO EAST", district: "Nkoranza North District", latitude: "7°32'57.7\" N", longitude: "1°47'30.8\" W", contact: "0242856541", email: "nketiaduncan@gmail.com" },
        { name: "Kintampo Manicipal Hospital", region: "BONO EAST", district: "Kintampo North Municipal District", latitude: "8°02'45.5\" N", longitude: "1°43'36.1\" W", contact: "0244972355", email: "isswud@gmail.com" },
        { name: "Kintampo South District Hospital", region: "BONO EAST", district: "Kintampo North Municipal District", latitude: "7°53'29.1\" N", longitude: "1°46'18.9\" W", contact: "0265603426", email: "mossay1970@gmail.com" },
        { name: "Tuobodom Health Centre", region: "BONO EAST", district: "Techiman North District", latitude: "7°38'14.1\" N", longitude: "1°54'46.0\" W", contact: "0543313402", email: "adjeibanchaglahf45@gmail.com" },
        { name: "Kwatire Hospital", region: "Bono", district: "Sunyani West District", latitude: "7°23'15.6\" N", longitude: "2°17'46.3\" W", contact: "0243739577", email: "ofori0247752906@gmail.com" },
        { name: "Sunyani municipal hospital", region: "Bono", district: "Sunyani Municipal", latitude: "7°20'15.2\" N", longitude: "2°19'44.2\" W", contact: "0244934873", email: "" },
        { name: "Bono Regional Hospital", region: "Bono", district: "Sunyani Municipal", latitude: "7°20'25.5\" N", longitude: "2°20'07.7\" W", contact: "0546388343", email: "" },
        { name: "Chiraa Hospital", region: "Bono", district: "Sunyani West District", latitude: "7°24'11.2\" N", longitude: "2°10'58.0\" W", contact: "0242459569", email: "kumifrimpongderek@yahoo.co.uk" },
        { name: "Fiappe Health Centre", region: "Bono", district: "Sunyani West District", latitude: "7°21'23.6\" N", longitude: "2°21'15.5\" W", contact: "0244866056", email: "" },
        { name: "Worae Care Clinic", region: "Bono", district: "Sunyani West District", latitude: "", longitude: "", contact: "0302781258", email: "" },
        { name: "Pokukrom Health Centre", region: "Bono", district: "Kintampo South District", latitude: "7°00'09.5\" N", longitude: "1°57'00.5\" W", contact: "0244292684", email: "" },
        { name: "Greenlight Pharmacy", region: "Bono", district: "Edwaso District", latitude: "7°20'11.2\" N", longitude: "2°19'41.0\" W", contact: "0538422937", email: "" }

    ],
    "Ahafo": [
        { name: "Techimantia Government Hospital", region: "Ahafo", district: "Techiman Municipal District", latitude: "7°35'27.4\" N", longitude: "1°56'03.5\" W", contact: "0208147381/0352122121", email: "" },
        { name: "Bibiani Municipal Hospital", region: "Ahafo", district: "Bibiani District", latitude: "6°27'39.8\" N", longitude: "2°19'01.9\" W", contact: "0243334801", email: "municipalhospital108bibiani@gmail.com" },
        { name: "Kukuom District Hospital", region: "Ahafo", district: "Asunafo South District", latitude: "6°41'48.5\" N", longitude: "2°26'19.3\" W", contact: "0242740272", email: "" },
        { name: "Goaso Municipal Government Hospital", region: "Ahafo", district: "Asunafo North District", latitude: "6°48'07.0\" N", longitude: "2°30'53.3\" W", contact: "0244929314", email: "ankomah28@gmail.com" },
        { name: "St Elizabeth Catholic Hospital", region: "Ahafo", district: "Asutifi District", latitude: "6°55'57.2\" N", longitude: "2°21'29.0\" W", contact: "0322298428", email: "info@sech-gh.org" },
        { name: "Kenyasi District Hospital", region: "Ahafo", district: "Asutifi North District", latitude: "6°58'32.0\" N", longitude: "2°23'09.4\" W", contact: "0545183001", email: "bonyinobed@gmail.com" },
        { name: "Banhart Hospital", region: "Ahafo", district: "Asutifi North District", latitude: "6°57'59.2\" N", longitude: "2°22'37.0\" W", contact: "0244602792", email: "" },
        { name: "Bechem Government Hospital", region: "Ahafo", district: "Tano South District", latitude: "7°05'17.5\" N", longitude: "2°01'47.3\" W", contact: "0247079088", email: "bechemhospital1950@yahoo.com" },
        { name: "Asunafo South District Hospital, Kukuom", region: "Ahafo", district: "Asunafo South District", latitude: "6°41'48.5\" N", longitude: "2°26'19.3\" W", contact: "0546291969", email: "josephtey20@gmail.com" },
        { name: "Holy Family Hospital", region: "Ahafo", district: "Techiman-Tamale Road, Techiman", latitude: "7°35'30.5\" N", longitude: "1°56'16.6\" W", contact: "0544353020", email: "hfhnkawkaw@chstgh.org" },
        { name: "Nsoatre Health Centre", region: "Ahafo", district: "Sunyani Municipal District", latitude: "7°23'56.6\" N", longitude: "2°27'47.0\" W", contact: "0554124547", email: "joeeesh79@yahoo.com" },
        { name: "Mim Health Center", region: "Ahafo", district: "Asunafo North", latitude: "7°54'01.4\" N", longitude: "1°33'32.3\" W", contact: "0207446162", email: "mazzymartin97@gmail.com" },
        { name: "Aspire Clinic and Maternity Home", region: "Ahafo", district: "Sunyani Municipal District", latitude: "7°20'38.2\" N", longitude: "2°15'54.2\" W", contact: "0542500793", email: "" },
        { name: "Divine Star Clinic and Maternity", region: "Ahafo", district: "Sunyani Municipal District", latitude: "5°47'42.2\" N", longitude: "0°09'22.3\" W", contact: "0303967440", email: "" },
        { name: "Rabito Clinic", region: "Ahafo", district: "Sunyani Municipal District", latitude: "7°18'13.3\" N", longitude: "2°17'53.0\" W", contact: "02093028008/0244974669", email: "info@rabitoclinic.com" },
        { name: "Chiraa Government Hospital", region: "Ahafo", district: "Sunyani West District", latitude: "7°24'11.0\" N", longitude: "2°10'58.1\" W", contact: "0352196718", email: "" },
        { name: "Medcourt Pharmacy", region: "Ahafo", district: "Sunyani Municipal District", latitude: "7°18'02.5\" N", longitude: "2°17'44.9\" W", contact: "0244464025/0505004915", email: "info@medcourtpharmacyltd.com" },
        { name: "Alma Medical", region: "Ahafo", district: "Sunyani West District", latitude: "", longitude: "", contact: "0243680058", email: "" },
        { name: "Bomaa Government Hospital", region: "Ahafo", district: "Tano North District", latitude: "7°05'15.7\" N", longitude: "2°10'04.6\" W", contact: "0243354856", email: "" },
        { name: "Bechem Government Hospital", region: "Ahafo", district: "Tano South District", latitude: "7°05'17.5\" N", longitude: "2°01'47.3\" W", contact: "0247079088", email: "bechemhospital1950@yahoo.com" },
        { name: "Abrafi Women And Children Hospital", region: "Ahafo", district: "Techiman Municipal District", latitude: "7°34'46.4\" N", longitude: "1°56'42.2\" W", contact: "0248521014", email: "yeboahdavid85@yahoo.com" },
        { name: "St. John of God Catholic Hospital", region: "Ahafo", district: "Tano North District", latitude: "5°46'19.9\" N", longitude: "0°08'24.7\" W", contact: "0243354856", email: "" }

    ],
    "Eastern": [
        { name: "Kade Government Hospital", region: "Eastern", district: "Kwaebibirem Municipal", latitude: "6°05'59.8\" N", longitude: "0°50'18.8\" W", contact: "0508321413", email: "kade.gher@ghs.gov.gh" },
        { name: "St John Catholic Polyclinic, Akim Ofoase", region: "Eastern", district: "West Akim Municipal", latitude: "", longitude: "", contact: "0249990902", email: "delsturbo@yahoo.com" },
        { name: "Sda Hospital, Koforidua", region: "Eastern", district: "New Juaben South Municipal", latitude: "6°05'45.4\" N", longitude: "0°15'56.9\" W", contact: "0541369379", email: "larbicompson@gmail.com" },
        { name: "Divine Victory Hospital", region: "Eastern", district: "West Akim Municipal", latitude: "5°55'31.6\" N", longitude: "0°59'11.2\" W", contact: "0244155101", email: "isaacfialor@gmail.com" },
        { name: "Atua Government Hospital", region: "Eastern", district: "Lower Manya Krobo Municipal", latitude: "6°07'11.8\" N", longitude: "0°00'30.4\" W", contact: "0247126996", email: "tamakloevincent100@yahoo.com" },
        { name: "Yilo Krobo Hospital", region: "Eastern", district: "Yilo Krobo Municipal", latitude: "6°04'12.2\" N", longitude: "0°01'47.3\" W", contact: "0244459757", email: "ekashiabi@gmail.com" },
        { name: "Kibi Government Hospital", region: "Eastern", district: "Upper West Akim District", latitude: "6°10'02.3\" N", longitude: "0°33'18.2\" W", contact: "0241394588", email: "popomicheal66@gmail.com" },
        { name: "Enyiresi Government Hospital", region: "Eastern", district: "Atiwa District", latitude: "6°26'13.0\" N", longitude: "0°35'12.5\" W", contact: "0549119192", email: "drebenamo@gmail.com" },
        { name: "Presbyterian Hospital, Donkokrom", region: "Eastern", district: "Kwahu North District", latitude: "7°02'55.7\" N", longitude: "0°04'37.4\" W", contact: "0249735012", email: "effahaugustine25@gmail.com" },
        { name: "Cato Clinic Ltd", region: "Eastern", district: "New Juaben North Municipal", latitude: "6°04'48.2\" N", longitude: "0°14'59.3\" W", contact: "0247572760", email: "dicksonkboakye@gmail.com" },
        { name: "New Abirem Government Hospital", region: "Eastern", district: "Birim Central Municipal", latitude: "6°20'14.8\" N", longitude: "0°59'47.6\" W", contact: "0244225832", email: "kwekuabu@gmail.com" },
        { name: "Oda Government Hospital", region: "Eastern", district: "New Juaben South Municipal", latitude: "5°55'42.8\" N", longitude: "0°59'09.4\" W", contact: "0541285991", email: "odagovernmenthospital@yahoo.com" },
        { name: "Nsawam Government Hospital", region: "Eastern", district: "Nsawam Adoagyire District", latitude: "5°48'38.5\" N", longitude: "0°20'13.9\" W", contact: "541116563", email: "nsawamgovernmenthospital@gmail.com" },
        { name: "Divine Victory Hospital", region: "Eastern", district: "Birim Central Municipal", latitude: "5°55'31.6\" N", longitude: "0°59'11.2\" W", contact: "0244155101/0202171044", email: "divinevictory@gmail.com" },
        { name: "Kenop Hospital Care", region: "Eastern", district: "Kwahu West District", latitude: "6°32'04.4\" N", longitude: "0°45'11.0\" W", contact: "03033939579/0243038761/054244580", email: "kenopcarehospital@yahoo.com" },
        { name: "Asamankese Government Hospital", region: "Eastern", district: "West Akim Municipal", latitude: "5°51'15.5\" N", longitude: "0°40'41.9\" W", contact: "0342091049", email: "" },
        { name: "Holy Family Hospital", region: "Eastern", district: "Kwahu West District", latitude: "6°32'50.8\" N", longitude: "0°46'24.8\" W", contact: "544353020", email: "hfhnkawkaw@chstgh.org" },
        { name: "Eastern Regional Hospital", region: "Eastern", district: "New Juaben South District", latitude: "6°05'50.3\" N", longitude: "0°15'23.2\" W", contact: "0243251680", email: "samadzadi@gmail.com" },
        { name: "St. Johns Hospital And Fertility Centre", region: "Eastern", district: "Nsawam Adoagyire District", latitude: "5°38'43.1\" N", longitude: "0°14'51.7\" W", contact: "0501258518", email: "info@stjohnshfc.com" },
        { name: "Newlands Medical Centre", region: "Eastern", district: "New Juaben District", latitude: "6°04'56.8\" N", longitude: "0°16'31.6\" W", contact: "0261218459", email: "nelandsmedicalcentre@gmail.com" },
        { name: "Rabito Clinic", region: "Eastern", district: "New Juaben District", latitude: "6°05'31.9\" N", longitude: "0°15'34.9\" W", contact: "0559740544", email: "info@rabitoclinic.com" },
        { name: "Catholic Community & Maternity Clinic", region: "Eastern", district: "Birim South Municipal", latitude: "5°53'22.3\" N", longitude: "1°00'58.6\" W", contact: "0302518428/0272202288", email: "ccmakimswedru@chstgh.org" },
        { name: "Tetteth Quarshie Memorial Hospital", region: "Eastern", district: "Akuapin North District", latitude: "5°55'18.5\" N", longitude: "0°07'58.1\" W", contact: "0501580029", email: "tgmhma61@gmail.com" }
    ],
    "Oti": [
        { name: "Bonakye Health Centre", region: "Oti", district: "Nkwanta South Municipal", latitude: "8°24'02.0\" N", longitude: "0°24'12.4\" E", contact: "0241818985", email: "stanleytornyo@gmail.com" },
        { name: "Nyambong Chps Zone", region: "Oti", district: "Nkwanta South Municipal", latitude: "", longitude: "", contact: "0245598564", email: "" },
        { name: "Brewaniase Health Center", region: "Oti", district: "Nkwanta North District", latitude: "8°00'44.8\" N", longitude: "0°33'34.9\" E", contact: "0249454126", email: "faustinaquayson@gmail.com" },
        { name: "Krachi West Municipal Hospital", region: "Oti", district: "Krachi West District", latitude: "7°47'38.6\" N", longitude: "0°03'27.2\" W", contact: "0547776579", email: "raphealtumuwa@gmail.com" },
        { name: "Worawora Government Hospital", region: "Oti", district: "Nkwanta North District", latitude: "7°31'09.5\" N", longitude: "0°22'15.8\" E", contact: "0243154192", email: "ahetebright@yahoo.com" },
        { name: "Kpassa Health Centre", region: "Oti", district: "Nkwanta North District", latitude: "8°29'44.9\" N", longitude: "0°18'50.2\" E", contact: "0547676441", email: "yahaya.yabara@ghs.gov.gh" },
        { name: "Abotoase Health Centre", region: "Oti", district: "Jasikan District", latitude: "7°27'07.9\" N", longitude: "0°20'20.6\" E", contact: "0246786138", email: "gnuworkpor@gmail.com" },
        { name: "Likpe Polyclinic", region: "Oti", district: "Krachi East Municipal", latitude: "7°09'46.6\" N", longitude: "0°34'51.8\" E", contact: "0540609003", email: "gershon.gesu@ghs.gov.gh" },
        { name: "Katanga Health Centre", region: "Oti", district: "Krachi East District", latitude: "7°39'30.4\" N", longitude: "0°19'59.9\" E", contact: "0247497888", email: "oparebeahasiedu@yahoo.com" },
        { name: "Comfort Ofedie Memorial Health Centre", region: "Oti", district: "Biakoye District", latitude: "7°07'54.1\" N", longitude: "0°19'41.0\" E", contact: "0246786138", email: "gnuworkpor@gmail.com" },
        { name: "Kpessa Health Centre", region: "Oti", district: "Nkwanta South Municipal", latitude: "8°29'44.9\" N", longitude: "0°18'50.0\" E", contact: "0547676441", email: "yahaya.yabara@ghs.gov.gh" },
        { name: "Dodo Amafrom Health Centre", region: "Oti", district: "Kadjebi District", latitude: "", longitude: "", contact: "0245493199", email: "bsekorme@gmail.com" },
        { name: "Dambai Health Centre", region: "Oti", district: "Krachi East Municipal", latitude: "8°03'51.1\" N", longitude: "0°11'10.7\" E", contact: "0240262069", email: "solomonamoah291@gmail.com" },
        { name: "Nkwanta South Municipal Hospital", region: "Oti", district: "Nkwanta South Municipal", latitude: "8°15'30.8\" N", longitude: "0°30'31.7\" E", contact: "0244884356", email: "" },
        { name: "Chinderi Health Centre", region: "Oti", district: "Krachi Nchumuru District", latitude: "8°08'42.7\" N", longitude: "0°09'30.4\" W", contact: "246521124", email: "amosbatsa75@gmail.com" },
        { name: "Kadjebi Health Centre", region: "Oti", district: "Kadjebi District", latitude: "7°32'04.0\" N", longitude: "0°28'24.8\" E", contact: "0242551037/0246706204", email: "" },
        { name: "St. Mary Theresa's Hospital", region: "Oti", district: "Kadjebi District", latitude: "7°44'31.4\" N", longitude: "0°30'37.1\" E", contact: "0501401283/0505139971", email: "smthdodipapase@chstgh.org" }

    ],
    "Northern": [
        { name: "Tolon District Hospital", region: "Northern", district: "Tolon District", latitude: "9.4252° N", longitude: "1.0512° W", contact: "0243528090", email: "tolonhospital@gmail.com" },
        { name: "Kpandai District Hospital", region: "Northern", district: "Kpandai District", latitude: "8°27'35.8\" N", longitude: "0°00'41.2\" W", contact: "0243732873", email: "mahal2003@yahoo.com" },
        { name: "Nanton Health Centre", region: "Northern", district: "Nanton District", latitude: "9°32'44.0\" N", longitude: "0°44'03.1\" W", contact: "0244970567", email: "nantondha@gmail.com" },
        { name: "Tamale West Hospital", region: "Northern", district: "Tamale Metropolitan", latitude: "9°24'02.5\" N", longitude: "0°50'59.8\" W", contact: "0242362695", email: "armohammed001@gmail.com" },
        { name: "Choggu Health Centre", region: "Northern", district: "Sagnarigu Municipal", latitude: "9°25'49.1\" N", longitude: "0°51'24.1\" W", contact: "0242211553", email: "napoliondon@gmail.com" },
        { name: "Karaga District Hospital", region: "Northern", district: "Karaga District", latitude: "9°54'55.6\" N", longitude: "0°25'52.7\" W", contact: "0244789790", email: "tiyuuniba@yahoo.com" },
        { name: "Tatale District Hospital", region: "Northern", district: "Tatale-Sanguli District", latitude: "9°21'49.7\" N", longitude: "0°31'47.3\" E", contact: "0246968070", email: "samsonadjei75@yahoo.com" },
        { name: "Sang Health Centre", region: "Northern", district: "Tatale-Sanguli District", latitude: "9°25'07.3\" N", longitude: "0°16'39.7\" W", contact: "0246655427", email: "dakoriviela1980@gmail.com" },
        { name: "Saboba Health Centre", region: "Northern", district: "Saboba District", latitude: "9°42'34.4\" N", longitude: "0°19'08.2\" E", contact: "0244987111", email: "haukiss@yahoo.com" },
        { name: "Kumbungu Health Centre", region: "Northern", district: "Kumbungu District", latitude: "9°33'12.8\" N", longitude: "0°56'31.0\" W", contact: "0245988548", email: "morojutta@gmail.com" },
        { name: "Zabzugu District Hospital", region: "Northern", district: "Zabzugu-Tatale District", latitude: "9°17'48.5\" N", longitude: "0°22'41.8\" E", contact: "0243877277", email: "sakomeh1@yahoo.com" },
        { name: "Northern Regional Hospital", region: "Northern", district: "Tamale Metropolitan", latitude: "9°24'16.4\" N", longitude: "0°50'11.6\" W", contact: "0244766565", email: "tengfahalice@gmail.com" },
        { name: "Yendi Municipal Hospital", region: "Northern", district: "Yendi Municipal", latitude: "9°26'40.9\" N", longitude: "0°00'16.9\" W", contact: "0243118643", email: "yendihospital@gmail.com" },
        { name: "Bimbimla Municipal Hospital", region: "Northern", district: "Nanumba North Municipal", latitude: "8°51'28.6\" N", longitude: "0°03'20.5\" E", contact: "0247913176", email: "gbadamahama@yahoo.com" },
        { name: "Gushegu Municipal Hospital", region: "Northern", district: "Gushegu Municipal", latitude: "9°55'45.1\" N", longitude: "0°12'50.6\" W", contact: "02042001834", email: "gushegumunicipalhospital@gmail.com" },
        { name: "Tamale SDA Hospital", region: "Northern", district: "Salaga Rd, Tamale", latitude: "9°23'58.4\" N", longitude: "0°49'54.5\" W", contact: "0372022073", email: "sdatamhosp@yahoo.com" },
        { name: "Tamale Teaching Hospital", region: "Northern", district: "Tamale Metropolitan", latitude: "9°23'35.0\" N", longitude: "0°49'24.4\" W", contact: "0372000180", email: "info@tth.gov.gh" },
        { name: "Tatale District Hospital", region: "Northern", district: "Tamale Metropolitan", latitude: "9°21'49.7\" N", longitude: "0°31'47.3\" E", contact: "0372000180", email: "info@tth.gov.gh" },
        { name: "Rabito Clinic", region: "Northern", district: "Tamale Metropolitan", latitude: "9°24'12.4\" N", longitude: "0°50'32.8\" W", contact: "0543115557", email: "info@rabitoclinic.com" }
    ],
    "Upper West": [
        { name: "Nadowli District Hospital", region: "Upper West", district: "Wa Municipal", latitude: "10°21'40.8\" N", longitude: "2°39'27.3\" W", contact: "0200215251/0549297000", email: "o.Inusah@yahoo.com" },
        { name: "Tumu Municipal Hospital", region: "Upper West", district: "Sissala East Municipal", latitude: "10°52'39.3\" N", longitude: "1°59'11.5\" W", contact: "0242952279/0502649555", email: "adnanab14@yahoo.com" },
        { name: "Lambussie Polyclinic", region: "Upper West", district: "Lambussie Karni District", latitude: "10°50'41.2\" N", longitude: "2°42'45.5\" W", contact: "0208184463", email: "sarpongalexa96@gmail.com" },
        { name: "Hain Polyclinic", region: "Upper West", district: "Jirapa District", latitude: "", longitude: "", contact: "0248205314", email: "abdulaziz40@gmail.com" },
        { name: "Funsi Health Centre", region: "Upper West", district: "Wa East District", latitude: "10°17'36.1\" N", longitude: "1°57'49.3\" W", contact: "0247215504", email: "likpiakibjamesbikilaati@gmail.com" },
        { name: "Upper West Regional Hospital", region: "Upper West", district: "Wa Municipal", latitude: "10°03'19.6\" N", longitude: "2°29'53.0\" W", contact: "0559481052", email: "fluguniah@yahoo.com" },
        { name: "Rabito Clinic", region: "Upper West", district: "Wa Municipal", latitude: "", longitude: "", contact: "302774526", email: "info@rabitoclinic.com" },
        { name: "Jirapa Urban Health", region: "Upper West", district: "Jirapa Municipal", latitude: "10°32'24.7\" N", longitude: "2°41'56.6\" W", contact: "0544487531", email: "empengyeng@gmail.com" },
        { name: "Lawra Municipal Hospital", region: "Upper West", district: "Lawra Municipal", latitude: "10°38'34.6\" N", longitude: "2°53'17.5\" W", contact: "0248940333/0248490367", email: "" },
        { name: "Nangodi Health Centre", region: "Upper West", district: "Nabdam District", latitude: "10.8631° N", longitude: "0.6614° W", contact: "0246779749", email: "maxwellavolsure@gmail.com" },
        { name: "Pelungu Health Centre", region: "Upper West", district: "Nabdam District", latitude: "10°48'01.6\" N", longitude: "0°41'43.6\" W", contact: "024981342", email: "ayinzemialoysiusdestin7@gmail.com" },
        { name: "Sissala West Municipal Hospital", region: "Upper West", district: "Sissala West Municipal", latitude: "10°58'57.7\" N", longitude: "2°13'21.7\" W", contact: "024038073", email: "" },
        { name: "Wa West District Hospital", region: "Upper West", district: "Wa West District", latitude: "9°49'34.3\" N", longitude: "2°40'55.4\" W", contact: "0243786735", email: "jadongo@ymail.com" }

    ],
    "Upper East": [
        { name: "Rabito Clinic", region: "Upper East", "district": "Tempane District", latitude: "", longitude: "", contact: "0559740553", email: "info@rabitoclinic.com" },
        { name: "Kassena-Nankana West (Paga) District Hospital", region: "Upper East", "district": "Kassena Nankana West District", latitude: "10°58'08.5\" N", longitude: "1°06'04.1\" W", contact: "0540908673", email: "catibambuya@st.edu.ug.gh" },
        { name: "Bolgatanga Regional Hospital", region: "Upper East", "district": "Bolgatanga Municipal", latitude: "10°48'24.0\" N", longitude: "0°51'42.8\" W", contact: "0244174143", email: "kwesidad69@gmail.com" },
        { name: "Bongo District Hospital", region: "Upper East", "district": "Bongo District", latitude: "10.9144° N", longitude: "0.8071° W", contact: "0249746397", email: "prosperamwienko@gmail.com" },
        { name: "Sandema Hospital", region: "Upper East", "district": "Bolgatanga Municipal", latitude: "10°43'59.6\" N", longitude: "1°17'16.4\" W", contact: "0244485996", email: "dsgariba2@gmail.com" },
        { name: "Zebilla Hospital", region: "Upper East", "district": "Garu District", latitude: "10°56'14.7\" N", longitude: "0°28'29.7\" W", contact: "0242770696", email: "gakugri@yahoo.com" },
        { name: "Talensi District Hospital", region: "Upper East", "district": "Talensi District", latitude: "10°42'50.2\" N", longitude: "0°47'40.5\" W", contact: "0208072154", email: "yzakariah@yahoo.com" }
    ],
    "Savannah": [
        { name: "Daboya Health Centre", region: "Savannah", district: "West Gonja Municipal", latitude: "9°31'43.7\"N", longitude: "1°22'53.2\"W", contact: "0542096080", email: "ayimaduemmanuel16@gmail.com" },
        { name: "Buipe Health Centre", region: "Savannah", district: "Central Gonja District", latitude: "8°47'01.3\"N", longitude: "1°28'10.0\"W", contact: "0243461219", email: "buipepolyclinic123@gmail.com" },
        { name: "Bole District Hospital", region: "Savannah", district: "Bole District", latitude: "9°01'41.9\"N", longitude: "2°29'49.0\"W", contact: "0205559021", email: "mohammedawalabdallah03@gmail.com" },
        { name: "Salaga Municipal Hospital", region: "Savannah", district: "East Gonja District", latitude: "8°33'26.3\"N", longitude: "0°30'54.2\"W", contact: "0243183885", email: "" },
        { name: "Sawla Tuna Kalba District Hospital", region: "Savannah", district: "Sawla-Tuna-Kalba District", latitude: "9°15'50.6\"N", longitude: "2°24'06.5\"W", contact: "0243958059", email: "akpeafaa@gmail.com" },
        { name: "Damongo Health Centre", region: "Savannah", district: "West Gonja Municipal", latitude: "9°04'57.2\"N", longitude: "1°49'05.0\"W", contact: "0243448794", email: "abdulganiyunuhu5@gmail.com" }
    ],
    "North East": [
        { name: "Walewale Municipal Hospital", regionName: "North East", location: "Walewale", contactNumber: "0596492224", email: "slouknaan@yahoo.com" },
        { name: "Nasuan Health Centre", regionName: "North East", location: "Nasuan", contactNumber: "0242205337", email: "ballahmusah@gmail.com" },
        { name: "Nalerigu College Of Health, Health Centre", regionName: "North East", location: "Nalerigu", contactNumber: "0247072678", email: "seidusalam53@gmail.com" },
        { name: "Binde Hospital", regionName: "North East", location: "Bende", contactNumber: "0244298735", email: "susanasign72@gmail.com" },
        { name: "Wa West District Hospital", regionName: "North East", location: "Wa", contactNumber: "0243786735", email: "jadongo@ymail.com" },
        { name: "Chereponi District Hospital", regionName: "North East", location: "Chereponi", contactNumber: "0243507575", email: "emmakanto4u@gmail.com" },
        { name: "Yunyoo Health Centre", regionName: "North East", location: "Yunyoo Nasuan", contactNumber: "0540715344", email: "ballahmusah@gmail.com" },
        { name: "Kuburi Polyclinic", regionName: "North East", location: "", contactNumber: "0242064000", email: "rafsaleman@gmail.com" },
        { name: "Yagaba Health Centre", regionName: "North East", location: "Yagaba", contactNumber: "0208256757", email: "rafsuleman@gmail.com" },
        { name: "Janga Hospital", regionName: "North East", location: "Janga", contactNumber: "0540833587", email: "valerialolig7@gmail.com" },
        { name: "Rabito Clinic", regionName: "North East", location: "Wa", contactNumber: "0502538689", email: "" }
    ],
    "Western North": [
        { name: "Essam Government Hospital", region: "Western North", district: "Bia West District", latitude: "6°40'00.5\"N", longitude: "3°06'30.8\"W", contact: "0553040973", email: "benebuah5@gmail.com" },
        { name: "Dadieso Government Hospital", region: "Western North", district: "Suaman District", latitude: "6°07'29.8\"N", longitude: "3°01'46.0\"W", contact: "0243819702", email: "sethkwame1977@yahoo.com" },
        { name: "Enchi Government Hospital", region: "Western North", district: "Aowin Municipal", latitude: "5°49'48.2\"N", longitude: "2°49'15.8\"W", contact: "0546291705", email: "bernamen2010@yahoo.com" },
        { name: "Sefwi Wiawso Municipal Hospital", region: "Western North", district: "Sefwi Wiaso District", latitude: "6°12'57.1\"N", longitude: "2°29'06.5\"W", contact: "0244638524", email: "razakjomo@yahoo.com" },
        { name: "Akontombra Government Hospital", region: "Western North", district: "Sefwi Akontombra District", latitude: "6°03'02.7\"N", longitude: "2°51'58.9\"W", contact: "0248007363", email: "rabbles.gyabeng@ghs.gov.gh" },
        { name: "Juaboso District Hospital", region: "Western North", district: "Juaboso District", latitude: "6.3343° N", longitude: "2.8302° W", contact: "0243844910", email: "leticiasafoa101@gmail.com" },
        { name: "Bibiani Municipal Hospital", region: "Western North", district: "Bibiani-Anhwiaso Bekwai Municipal", latitude: "6.4565° N", longitude: "2.3226° W", contact: "0244823798", email: "" }
    ],

}
